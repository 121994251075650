import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JobService } from 'src/app/services/job.service';

import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AgentUser } from 'src/app/type';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
})
export class CompanyHomeComponent implements OnInit {
  companies: Array<any> = [];
  dropdownAgents = [];
  selectedAgent = [];
  selectedCompanyId: any;
  screenHeight: number;
  companyForm: UntypedFormGroup;
  companyDetails: string = '';
  search = new UntypedFormControl();
  comName = new UntypedFormControl();
  companySearch: string = '';
  companySearchUpdate = new Subject<string>();
  searchSubcription: Subscription;
  mymodel: string = '';
  selectedOptions: number[];
  companyId: number;
  page: number = 1;
  url: string;
  nextPage: string;
  noOfItemsToShowInitially: number = 5;
  itemsToLoad: number = 5;
  isFullListDisplayed: boolean = false;
  dropdownSettings: IDropdownSettings;
  isFetchingCompanies: boolean = false;

  constructor(
    private jobService: JobService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this.screenHeight = window.innerHeight - 10;

    this.searchSubcription = this.companySearchUpdate
      .pipe(debounceTime(400))
      .subscribe(value => {
        this.getCompanyUser(true);
      });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };
    this.companyForm = this._formBuilder.group({
      search: [],
    });
    this.getCompanyUser();
    this.getAgentList();
  }

  ngOnDestroy() {
    this.searchSubcription.unsubscribe();
  }

  getCompanyUser(reset = false) {
    if (this.nextPage === null && !reset) return;
    if (reset) this.page = 1;

    const cName = this.companyForm.value.search || '';
    const agent_ids = this.selectedAgent.map(agent => agent.item_id).join(',');

    if (cName.length == 0 || cName.length > 2) {
      this.isFetchingCompanies = true;
      this.jobService
        .getCompaniesByName(this.page, {
          companyName: encodeURIComponent(cName),
          agentIds: agent_ids.length ? agent_ids : '',
          minimal: true,
        })
        .subscribe({
          next: (response: any) => {
            this.nextPage = response.next;

            if (this.page > 1) {
              this.companies = this.companies.concat(response.results);
            } else {
              this.companies = response.results;
            }

            if (this.route.params['_value'].id) {
              this.companyId = this.route.params['_value'].id;
            } else {
              this.companyId = this.companies?.[0]?.id ?? '';
              this._router.navigate(['companies', this.companyId]);
            }

            if (reset && !cName) this.companyDetails = this.companies[0];

            this.selectedOptions = this.companyId
              ? [this.companyId]
              : [this.companies[0]?.id];
          },
          complete: () => {
            this.isFetchingCompanies = false;
          },
        });
    }
  }

  getAgentList() {
    this.jobService.agents.subscribe((res: AgentUser[]) => {
      this.dropdownAgents = (res ?? []).map(agent => {
        return {
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        };
      });
    });
  }

  selectedCompany(cName, company_id) {
    this._router.navigate(['companies', company_id]);
    this.companyDetails = cName;
  }

  refreshCompany(companyArray) {
    var target = this.companies.findIndex(temp => temp?.id == companyArray?.id);
    this.companies[target] = companyArray;
  }

  onScroll() {
    if (this.noOfItemsToShowInitially <= this.companies.length) {
      this.page = this.page + 1;
      this.getCompanyUser();
    } else {
      this.isFullListDisplayed = true;
    }
  }
}
