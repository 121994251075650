import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobHomeComponent } from '../../job-home/job-home.component';

@Component({
  selector: 'app-job-delete-dialog',
  templateUrl: './job-delete-dialog.component.html',
  styleUrls: ['./job-delete-dialog.component.scss']
})
export class JobDeleteDialogComponent implements OnInit {

  

  messageType: any;
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<JobHomeComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
    ) {
      this.messageType = data.messageType;
    }
    ngOnInit(): void {
  }
    
  doRefresh() {}

  cancel() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: 'you cancelled' });
  }

  confirm() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: 'you confirmed' });
  }

}
