import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { Observable } from 'rxjs';

export interface customQuery {
  companies: string;
  search: string;
}

export interface matchQuery {
  stage?: string;
  companies: string;
  search: string;
  status: string;
  need_action: string;
  company_ids: string;
  start_date: string;
  end_date: string;
  education_type?: string;
  continuing_education?: string;
  applier_education_type?: string;
  applier_continuing_education?: string;
  account_manager_ids?: string;
  reference_by?: string[];
}

export interface customQueryV2 {
  companies: string | {};
  search: string;
  shift: string | {};
  care_type: string | {};
  agent_user: string | {};
  working_type: string | {};
  education_type: string | {};
  continuing_education: string | {};
  active: string | {};
  radius_search: string | {};
  latitude: string | {};
  longitude: string | {};
  position?: string;
}

export interface postingQuery {
  company_ids: string | {};
  pincode: string;
  care_type: string | {};
  posting_templates: string | {};
  budget: string | {};
  start_date: string;
  end_date: string;
}

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  get(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  post(url, data, httpOptions = {}) {
    return this.http
      .post(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  put(url, data, httpOptions = {}) {
    return this.http
      .put(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getCompanies({ minimal = false } = {}) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company/?minimal=${minimal}`, httpOptions);
  }

  getCompaniesByName(page, { companyName, agentIds = '', minimal = false }) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/company/?search=${companyName}&agent_ids=${agentIds}&page=${page}&minimal=${minimal}&pagination=True`,
      httpOptions
    );
  }

  getAgent(showOwnLogin) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/user/?role=2&matchdropdown=${showOwnLogin}`,
      httpOptions
    );
  }

  getCompaniesByCompanyUser() {
    const sesstionResult = JSON.parse(localStorage.getItem('companyData'));
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/company?company_id=${sesstionResult?.company_id}`,
      httpOptions
    );
  }

  getUsers() {
    let httpOptions = this.authService.getHTTPOption();
    //TODO: Add role in .env and take it from there
    return this.get(`accounts/user/?role=3`, httpOptions);
  }

  getCompanyDetails(companyId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company/${companyId}/`, httpOptions);
  }

  getCompanyFacilities(companyId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`company/${companyId}/health-facilities/`, httpOptions);
  }

  getJobs(companyId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/jobfilter-by-company/${companyId}/`, httpOptions);
  }

  getJobDetails(jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/job/${jobId}/`, httpOptions);
  }

  getJobHistory(jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/job-history/${jobId}/`, httpOptions);
  }

  getApplierDetails(applierId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier/${applierId}/`, httpOptions);
  }

  updateJobDetails(jobId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`jobs/job/${jobId}/`, data, httpOptions);
  }

  updateCompanyDetailsV2(companyId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/company/${companyId}/`, data, httpOptions);
  }

  updateContactUserStatus(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/change-contact-user/`, data, httpOptions);
  }

  updateCompanyPresentation(companyId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/company-update/${companyId}/`, data, httpOptions);
  }

  addCompanyDetails(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/company/`, data, httpOptions);
  }

  createUser(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/user/`, data, httpOptions);
  }

  uploadCSV(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`jobs/job-csv/`, data, httpOptions);
  }

  addJobDetails(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`jobs/job/`, data, httpOptions);
  }

  getLoginUserDetails(userId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/user/${userId}?dropdown=false/`, httpOptions);
  }

  updateUser(userId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(
      `accounts/user/${userId}/?dropdown=false/`,
      data,
      httpOptions
    );
  }

  updateJobStatus(data, jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`jobs/job-status/${jobId}/`, data, httpOptions);
  }

  public handleError(errorRes: HttpErrorResponse) {
    if (Object.keys(errorRes?.error || {}).length) {
      if (
        Object.keys(errorRes?.error)[0]?.length > 1 &&
        errorRes?.statusText !== 'Unknown Error'
      ) {
        return throwError(
          Object.keys(errorRes.error)[0] +
            ' ' +
            Object.values(errorRes.error)[0]
        );
      }
    }
    let message = errorRes?.statusText
      ? errorRes?.statusText
      : 'etwas ist schief gelaufen!';
    return throwError(message);
  }
  match(request: PageRequest<any>, query: matchQuery): Observable<Page<any>> {
    const httpOptions = this.authService.getHTTPOption();
    let ordering = '';
    if (request.sort.order.length) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }
    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        agent_ids: query?.companies.toString(),
        account_manager_ids: (query?.account_manager_ids ?? '').toString(),
        search: query?.search,
        status_id: query?.status.toString(),
        stage: query?.stage.toString(),
        need_action: query?.need_action == 'true' ? 'True' : 'False',
        company_ids: query?.company_ids.toString(),
        start_date: query?.start_date.toString(),
        end_date: query?.end_date.toString(),
        education_type: (query?.education_type ?? '').toString(),
        continuing_education: (query?.continuing_education ?? '').toString(),
        reference_by: (query?.reference_by ?? '').toString(),
        applier_education_type: (
          query?.applier_education_type ?? ''
        ).toString(),
        applier_continuing_education: (
          query?.applier_continuing_education ?? ''
        ).toString(),
      },
      ...httpOptions,
    };

    const url = `match/matching/`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map(res => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  pageV2(
    request: PageRequest<any>,
    query: customQueryV2
  ): Observable<Page<any>> {
    const httpOptions = this.authService.getHTTPOption();
    let ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        search: query?.search,
        company_ids: query?.companies.toString(),
        shift: (query?.shift ?? '').toString(),
        care_type: (query?.care_type ?? '').toString(),
        agent_user: (query?.agent_user ?? '').toString(),
        working_type: (query?.working_type ?? '').toString(),
        education_type: (query?.education_type ?? '').toString(),
        continuing_education: (query?.continuing_education ?? '').toString(),
        active: (query?.active ?? '').toString(),
        radius_search: (query?.radius_search ?? '').toString(),
        latitude: (query?.latitude ?? '').toString(),
        longitude: (query?.longitude ?? '').toString(),
        position: (query?.position ?? '').toString(),
      },
      ...httpOptions,
    };

    let url = `jobs/job`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}/`, params)
      .pipe(
        map(res => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  posting(
    request: PageRequest<any>,
    query: postingQuery
  ): Observable<Page<any>> {
    const httpOptions = this.authService.getHTTPOption();
    let ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        pincode: query?.pincode,
        company_ids: query?.company_ids.toString(),
        care_types: query?.care_type == '' ? '' : query?.care_type?.toString(),
        budget: query?.budget == '' ? '' : query?.budget?.toString(),
        posting_templates:
          query?.posting_templates == ''
            ? ''
            : query?.posting_templates?.toString(),
        start_date: query?.start_date.toString(),
        end_date: query?.end_date.toString(),
      },
      ...httpOptions,
    };
    let url = `jobs/posting`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map(res => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  page(request: PageRequest<any>, query: customQuery): Observable<Page<any>> {
    const httpOptions = this.authService.getHTTPOption();
    let ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        company_ids: query?.companies.toString(),
        shift: '',
        care_type: '',
        agent_user: '',
        working_type: '',
        education_type: '',
        search: query?.search,
      },
      ...httpOptions,
    };
    let url = `jobs/job`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map(res => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }
  delete(url, httpOptions = {}) {
    return this.http
      .delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getJobDelete(jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.delete(`jobs/job/${jobId}/`, httpOptions);
  }

  getCompanyUser(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/get-company-user/?company_id=${data?.company_id}`,
      httpOptions
    );
  }

  getCompanyImage(companyId, imageType) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/company_image_upload/?company_id=${companyId}&picture_type=${imageType}`,
      httpOptions
    );
  }

  deleteImage(companyId: number, imageName: string, imageType: string) {
    const httpOptions = this.authService.getHTTPOption();
    return this.delete(
      `accounts/company_image_upload/?company_id=${companyId}&image=${imageName}&picture_type=${imageType}`,
      httpOptions
    );
  }
}
