import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { Sort } from '@angular/material/sort';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AgentDetailsDialogComponent } from '../../common/agent-details-dialog/agent-details-dialog.component';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.scss'],
})
export class AgentCompanyDashboardComponent implements OnInit, AfterViewInit {
  // @Output() isJobUpdated = new EventEmitter<any>();
  subscriptions: Array<Subscription> = [];
  companies = [];
  companyId: number;
  jobId: number;
  tabId: number;
  isCopy: number;
  isJobUpdated: any;
  currentUrl: string;
  previousUrl: string;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isNewJob = false;
  parentCompanyId: 0;
  isDublicate: boolean = false;
  // sidebar and tab stuff
  @ViewChild('drawer') drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // add the datasource
  sorting_order: null;
  search: null;
  curentPage: 0;
  agent_user: null;
  companyDetails: null;
  columnsToDisplay = [
    'companyname',
    'city',
    'education',
    'caretype',
    'shifthour',
    'scope',
    'created',
    'action',
  ];
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.page(request, query),
    { property: '' as keyof Sortable, order: 'desc' },
    { companies: '', search: '' },
    2
  );

  // filter stuff
  dropdownSettings: IDropdownSettings;
  dropdownCompanies = [];
  companiesList = [];
  selectedCompanies = this.dropdownCompanies;

  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    public dialog: MatDialog
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.companyId = +params['id'];
        this.jobId = +params['jobId'];
        this.tabId = +params['tabId'];
        this.isCopy = +params['isCopy'];
        this.isNewCompany = false;
        this.isNewJob = false;
      })
    );
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      // selectAllText: 'Alle auswählen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      // limitSelection: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      // allowRemoteDataSearch: true,
      clearSearchFilter: true,
    };

    this.getCompanyUser();
  }

  searchFilterData(val) {
    const companyId = val.map(x => x.item_id);
    const searchVal = this.search !== undefined ? this.search : '';
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.page(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      { companies: companyId, search: searchVal },
      2
    );
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }
  getCompanyUser() {
    this.jobService.getCompaniesByCompanyUser().subscribe((res: any) => {
      let Companies = [];
      this.agent_user = res.agent_user;

      res.forEach(company =>
        Companies.push({ item_id: company.id, item_text: company.name })
      );
      this.dropdownCompanies = Companies;
      this.companiesList = Companies;
    });
  }
  ngAfterViewInit() {
    if (
      (this.tabId == 0 || this.tabId) &&
      this.jobId &&
      this.companyId &&
      this.isCopy == 0
    ) {
      this.edit(this.companyId, this.jobId, this.tabId, this.isCopy);
    } else if (this.isCopy == 1) {
      this.edit(this.companyId, this.jobId, this.tabId, this.isCopy);
    }
  }

  startClose() {
    this.location.replaceState('/dashboard');
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getCompanyUser();
  }
  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }
  // add job create and edit stuff
  addNewJob(companyid) {
    this.selectedTab.select(0);
    this.companiesList = this.dropdownCompanies;
    this.drawer.open();
    this.isNewJob = true;
    this.isNewCompany = false;
    this.isDublicate = false;
  }

  // add company create and edit stuff
  addNewCompany() {
    this.drawer.open();
    this.selectedTab.select(1);
    this.isNewCompany = true;
    this.isDublicate = false;
    this.isNewJob = false;
  }

  //edit sidebar stuff
  edit(companyid, jobid, tabid, isCopy) {
    this.isDublicate = isCopy == 0 ? false : true;
    this.drawer.open();
    this.selectedTab.select(tabid);
    this.companyId = companyid;
    this.jobId = jobid;
    this.isNewJob = false;
    this.isNewCompany = false;
    this.location.replaceState(
      '/dashboard/' + companyid + '/' + jobid + '/' + tabid + '/' + isCopy
    );
    this.cdr.detectChanges();
  }

  checkIsJobUpdated($event) {
    this.getCompanyUser();
    this.isJobUpdated = $event;
    // this.isJobUpdated.emit($event);
  }

  /* set job status active or inactive */
  updateJobStatus(jobId, status) {
    let statusData = { is_active: status.checked };
    this.jobService.updateJobStatus(statusData, jobId).subscribe(
      (res: any) => {
        this.dataSource.fetch(this.paginator.pageIndex);
      },
      error => {
        this._snackBar.open(error, 'OK', { duration: 5000 });
      }
    );
  }

  delete(jobId) {
    this.jobService.getJobDelete(jobId).subscribe(
      (res: any) => {
        this.dataSource.fetch(this.paginator.pageIndex);
        this._snackBar.open('Job erfolgreich gelöscht', 'OK', {
          duration: 5000,
        });
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
    this.cdr.detectChanges();
  }

  showDashboard(agent_user) {
    const dialogRef = this.dialog.open(AgentDetailsDialogComponent, {
      data: { companyDetails: agent_user },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.info('The dialog was closed');
    });
  }
}

export interface companyResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}
