import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from 'src/app/models/abbreviation.model';
import { actors, getActor } from 'src/app/models/abbreviations/applierStatus';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss'],
})
export class JobHistoryComponent implements OnInit {
  @Input() jobId: number;
  histories: any;
  getActorLabel = getActor;

  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public constants: Constants
  ) {}

  ngOnInit(): void {
    this.getJobHistory();
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  getStatusText(status): string {
    return this.constants.getJobStage(status)?.label ?? '';
  }

  getFieldLabel(changes: {
    field: string;
    old: any[] | number;
    new: any[] | number;
  }) {
    const mappings = {};

    return mappings[changes.field] ?? changes.field;
  }

  statusChangeLabel(log) {
    if (!log?.old_status?.length && log?.new_status)
      return 'Ein neues Match wurde gefunden';
    if (log.actor === actors.SYSTEM.id)
      return `Das System hat den Status zu "${this.getStatusText(
        log?.new_status
      )}" geändert`;

    return `${log?.user?.first_name} ${log?.user?.last_name}  (${
      getActor(log?.actor)?.label
    })  hat den Status zu "${this.getStatusText(log?.new_status)}" geändert`;
  }

  getJobHistory() {
    this.jobService.getJobHistory(this.jobId).subscribe(
      (data: Array<any>) => {
        const histories = (data ?? []).reduce((accumulator, currentValue) => {
          const key = new Date(currentValue?.created_at)
            .toISOString()
            .split('T')[0];
          if (!accumulator[key]) accumulator[key] = [];
          accumulator[key].push(currentValue);
          return accumulator;
        }, {});

        this.histories = histories;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }
}
