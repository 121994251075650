import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConstItem } from 'src/app/services/constants.service';
import { HealthFacility } from 'src/app/services/health-facility-service.service';
import { JobService } from 'src/app/services/job.service';
import { Constants } from '../../../models/abbreviation.model';
import { ValidationAtLeastOneFieldService } from '../../../services/validation-at-least-one-field.service';
import { HealthFacilityFormComponent } from '../health-facility-form/health-facility-form.component';

const codes = require('german-postal-codes');

@Component({
  selector: 'app-job-sidebar',
  templateUrl: './job-sidebar.component.html',
  styleUrls: ['./job-sidebar.component.scss'],
})
export class JobSidebarComponent implements OnInit, OnDestroy {
  @Input('jobId') jobId: number;
  @Input('isNewJob') isNewJob: any = 'false';
  @Input('isDublicate') isDublicate: string;
  @Input() editMode: boolean = true;
  @Input('dropdownCompanies') dropdownCompanies: any[];
  @Input('companyId') companyId: number;
  @Output() isJobUpdated = new EventEmitter<any>();
  @Output() updateAndClose = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  $companyChange: Subscription;
  jobForm: FormGroup;
  jobDetails: any;
  dublicateDetails: any;
  dropdownSettings: IDropdownSettings;
  singleSelectiondropdownSettings: IDropdownSettings;
  multiSelectWithSearch: IDropdownSettings;
  singleSelectWithSearch: IDropdownSettings;
  facilities = [];
  selectedPosition = [];
  selectedShiftHoursItems = [];
  selectedCareTypeItems = [];
  selectedCompanies = [];
  selectedContinuingEducation = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  specificationCareItems = [];
  specialRequirementJobTypeItems = [];
  street: string;
  streetNumber: string;
  city: string;
  isSpinner: boolean = false;
  address: string;
  errorMessage: string;
  isValueChange: boolean;
  userRole: number;
  showJobHistory: boolean = false;
  //Local Variable defined
  formattedAddress = ' ';
  options = {
    componentRestrictions: {
      country: ['de'],
    },
  };
  isPostcodeValid = false;
  isShowSalary = false;
  minSalary: number = 2550;
  maxSalary: number = 3200;
  Options: Options = {
    floor: 1500,
    ceil: 5500,
  };

  constructor(
    private jobService: JobService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public constants: Constants
  ) {}

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    this.userRole = userData.role;
    this.jobForm = this._formBuilder.group(
      {
        position: ['', Validators.required],
        position_other: [],
        id: [],
        title: [],
        facility: [null, Validators.required],
        status: ['', Validators.required],
        companyName: ['', Validators.required],
        careTypes: ['', Validators.required],
        shifts: [''],
        workingTypes: ['', Validators.required],
        educationTypes: [''],
        selectedScopeItems: [],
        selectedEducationItems: [],
        specificationCareItems: [],
        specialRequirementJobTypeItems: [],
        shift_other: [],
        continuing_education: [],
        note: [],
        internal_note: [],
        url: [],
        max_salary: [],
        min_salary: [],
      },
      {
        validator: ValidationAtLeastOneFieldService(Validators.required, [
          'shift_other',
          'shifts',
        ]),
      }
    );

    if (this.jobId && this.isNewJob !== 'true') {
      this.getJobDetails();
    }
    this.inItSelect2List();
    if (this.isNewJob == 'true') {
      this.jobForm.patchValue({ isJobActive: 1 });
    }
    this.cdr.detectChanges();

    if (this.jobId && this.isNewJob == 'true') {
      this.initNewForm();
    }

    this.isValueChange = true;
    if (this.isDublicate === 'true') {
      this.isValueChange = false;
    }

    this.$companyChange = this.jobForm
      ?.get('companyName')
      .valueChanges.subscribe(val => {
        if (!val.length) return;
        this.getCompanyFacilities(val[0]?.id);
      });
  }

  ngOnDestroy(): void {
    this.$companyChange.unsubscribe();
  }

  get companyName() {
    return this.jobForm.get('companyName');
  }

  getCompanyFacilities(company_id?: number) {
    const companyId = this.jobDetails?.company?.id || company_id;
    if (!companyId) return;
    this.jobService.getCompanyFacilities(companyId).subscribe((res: any) => {
      this.facilities = res?.results;
    });
  }

  createFacility(jobId: number) {
    if (
      !this.companyId &&
      !isNaN(this.companyId) &&
      !this.companyName.value.length
    )
      return;

    const dialogRef = this.dialog.open(HealthFacilityFormComponent, {
      data: {
        companyId: isNaN(this.companyId)
          ? this.companyName.value[0].id
          : this.companyId,
      },

      panelClass: 'health-facility',
    });
    dialogRef.afterClosed().subscribe((result: HealthFacility) => {
      if (!result) return;
      this.facilities.unshift(result);
      this.jobForm.patchValue({
        facility: result?.id,
      });
    });
  }

  getJobDetails() {
    if (!Boolean(this.jobId)) return;

    this.isSpinner = true;
    this.jobForm?.get('companyName').clearValidators();
    this.jobForm?.get('companyName').updateValueAndValidity();
    this.jobService.getJobDetails(this.jobId).subscribe((res: any) => {
      this.jobDetails = res;
      this.getCompanyFacilities();

      this.inItSelect2List(this.jobDetails);

      if (this.jobDetails.min_salary && this.jobDetails.max_salary) {
        this.isShowSalary = true;
        this.minSalary = this.jobDetails.min_salary;
        this.maxSalary = this.jobDetails.max_salary;
      }

      this.jobForm.patchValue({
        id: this.jobDetails.id,
        title: this.jobDetails.title,
        shift_other: this.jobDetails.shift_other,
        position_other: this.jobDetails.position_other,
        note: this.jobDetails.note,
        status: this.jobDetails.status,
        internal_note: this.jobDetails.internal_note,
        isJobActive: this.jobDetails.is_active == 1 ? true : false,
        url: this.jobDetails.url,
        min_salary: this.jobDetails.min_salary,
        max_salary: this.jobDetails.max_salary,
        facility: this.jobDetails.health_facility_location?.id,
      });

      this.checkSalary(this.jobDetails.min_salary, this.jobDetails.max_salary);

      if (this.isDublicate === 'true') {
        let dublicateArray = {
          company_id:
            this.isNewJob == 'true'
              ? this.selectedCompanies[0].id
              : this.isDublicate === 'true'
              ? this.jobDetails?.company?.id
              : '',
          facility: this.jobDetails.health_facility_location?.id,
          is_active: this.jobDetails.is_active,
          shift_other: (this.jobForm?.value?.shift_other ?? '').trim(),
          note: this.jobDetails.note,
          postion_other: this.jobDetails.position_other,
          internal_note: (this.jobDetails?.internal_note ?? '').trim(),
          care_type: this.jobDetails.care_type,
          shift: this.jobDetails.shift,
          education_type: this.jobDetails.education_type,
          working_type: this.jobDetails.working_type,
          specification_care_type: this.jobDetails.specification_care_type,
          special_requirements: this.jobDetails.special_requirements,

          url: (this.jobForm?.value?.url ?? '').trim(),
          min_salary: this.isShowSalary ? this.minSalary : null,
          max_salary: this.isShowSalary ? this.maxSalary : null,
          continuing_education: this.jobDetails.continuing_education,
          position: this.jobDetails.position,
        };
        this.dublicateDetails = dublicateArray;
      }
      this.isSpinner = false;
    });
  }

  showSalary(e: boolean) {
    this.isShowSalary = e;
  }

  checkSalary(min, max) {
    this.minSalary = min;
    this.maxSalary = max;

    if (this.minSalary > this.maxSalary) {
      this.jobForm.controls['min_salary'].setErrors({ invalid: true });
    } else {
      this.jobForm.controls['min_salary'].setErrors(null);
    }
  }

  initNewForm() {
    this.address = '';
    this.selectedShiftHoursItems = [];
    this.selectedCareTypeItems = [];
    this.selectedScopeItems = [];
    this.selectedEducationItems = [];
    this.specificationCareItems = [];
    this.selectedCompanies = [];
    this.specialRequirementJobTypeItems = [];
    if (this.jobForm) {
      this.jobForm.patchValue({
        title: '',
        isJobActive: 1,
        selectedScopeItems: '',
        selectedEducationItems: '',
        specificationCareItems: '',
        specialRequirementJobTypeItems: '',
        note: '',
        position_other: '',
        url: '',
        internal_note: '',
      });
    } else {
      this.jobForm = new FormGroup({
        id: new FormControl(''),
        title: new FormControl(''),
        isJobActive: new FormControl(''),
        companyName: new FormControl(''),
        careTypes: new FormControl(''),
        shifts: new FormControl(''),
        workingTypes: new FormControl(''),
        educationTypes: new FormControl(''),
      });
      this.jobForm.patchValue({
        title: '',
        isJobActive: 1,
        selectedScopeItems: '',
        selectedEducationItems: '',
        specificationCareItems: '',
        specialRequirementJobTypeItems: '',
        note: '',
        url: '',
        internal_note: '',
      });
    }
  }

  inItSelect2List(data = null) {
    const baseDropDownSettigs = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownSettings = {
      ...baseDropDownSettigs,
      singleSelection: false,
    };

    this.singleSelectiondropdownSettings = {
      ...baseDropDownSettigs,
    };

    this.singleSelectWithSearch = {
      ...this.singleSelectiondropdownSettings,
      allowSearchFilter: true,
    };

    this.multiSelectWithSearch = {
      ...this.dropdownSettings,
      allowSearchFilter: true,
    };

    this.constants
      .getList('position')
      .pipe(take(1))
      .subscribe(positions => {
        this.selectedPosition = (data?.position ?? []).flatMap(position =>
          positions.filter((p: ConstItem) => p.id === position)
        );
      });

    if ((data?.care_type ?? []).length) {
      this.constants
        .getList('care_type')
        .pipe(take(1))
        .subscribe(care_types => {
          data?.care_type.forEach(care_type => {
            const careType = care_types.find(
              (c: ConstItem) => c.id === care_type
            );
            this.selectedCareTypeItems.push(careType);
          });
        });
    }

    if (data?.company) {
      this.selectedCompanies.push({
        id: data?.company.id,
        label: data?.company.name,
      });
    }

    if ((data?.continuing_education ?? []).length) {
      this.constants
        .getList('continuing_education_type')
        .pipe(take(1))
        .subscribe(choices => {
          data?.continuing_education.forEach(education => {
            const continuingEducation = choices.find(
              (choice: ConstItem) => choice?.id === education?.[0]
            );
            this.selectedContinuingEducation.push(continuingEducation);
          });
        });
    }

    if ((data?.shift ?? []).length) {
      this.constants
        .getList('shift_hour_type')
        .pipe(take(1))
        .subscribe(shifts => {
          data?.shift.forEach(shift => {
            const shiftHour = shifts.find((s: ConstItem) => s.id === shift);
            this.selectedShiftHoursItems.push(shiftHour);
          });
        });
    }

    if ((data?.education_type ?? []).length) {
      this.constants
        .getList('education_type')
        .pipe(take(1))
        .subscribe(choices => {
          data?.education_type.forEach(education => {
            const selectedEducation = choices.find(
              (choice: ConstItem) => choice.id === education
            );
            this.selectedEducationItems.push(selectedEducation);
          });
        });
    }

    if ((data?.working_type ?? []).length) {
      this.constants
        .getList('shift_time_type')
        .pipe(take(1))
        .subscribe(shifts => {
          data?.working_type.forEach(shift => {
            const shiftTime = shifts.find((s: ConstItem) => s.id === shift);
            this.selectedScopeItems.push(shiftTime);
          });
        });
    }

    if ((data?.specification_care_type ?? []).length) {
      this.constants
        .getList('specification_job_care_type')
        .pipe(take(1))
        .subscribe(choices => {
          data?.specification_care_type.forEach(care_type => {
            const specificationCareItems = choices.find(
              (choice: ConstItem) => choice.id === care_type
            );
            this.specificationCareItems.push(specificationCareItems);
          });
        });
    }

    if (data?.special_requirements) {
      this.constants
        .getList('special_requirement_job_type')
        .pipe(take(1))
        .subscribe(choices => {
          data?.special_requirements.forEach(requirement => {
            const requirements = choices.find(
              (choice: ConstItem) => choice.id === requirement
            );
            this.specialRequirementJobTypeItems.push(requirements);
          });
        });
    }
  }

  checkPostCode(event: any) {
    if (
      this.jobForm.value.postcode !== 'undefined' &&
      this.jobForm.value.postcode
    ) {
      var target = codes.find(temp => temp == this.jobForm.value.postcode);
      if (target) this.isPostcodeValid = false;
      else this.isPostcodeValid = true;
    }
  }

  async doUpdateJob(isClose = false, $event) {
    this.errorMessage = '';
    let id = this.isNewJob == 'true' ? '' : this.jobForm.value.id;
    if (this.selectedCompanies.length == 0) {
      this._snackBar.open(
        this.translate.instant('message.select_user_message'),
        'OK'
      );
      return false;
    }

    $event.target.parentElement.disabled = true;
    $event.target.parentElement.classList.add('mat-button-disabled');
    if (this.isNewJob != 'true' && this.isDublicate === 'false') {
      let data = this.checkValueChange();
      await this.jobService.updateJobDetails(id, data).subscribe(
        (response: any) => {
          this.isJobUpdated.emit('update ' + new Date().getTime());
          this._snackBar.open(
            this.translate.instant('message.change_changed'),
            'OK',
            { duration: 9000 }
          );
          if (isClose) {
            this.updateAndClose.emit(response);
          } else {
            this.update.emit(response);
          }
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
        },
        errorRes => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          this.errorMessage = errorRes;
        }
      );
    } else {
      let data = this.checkValueChange();
      await this.jobService.addJobDetails(data).subscribe(
        (response: any) => {
          this._snackBar.open(
            this.translate.instant('message.change_added'),
            'OK',
            { duration: 9000 }
          );
          if (isClose) {
            this.updateAndClose.emit(response);
          } else {
            this.update.emit(response);
          }
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
        },
        errorRes => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          this.errorMessage = errorRes;
        }
      );
    }
  }

  resetData() {
    this.close.emit();
  }

  checkValueChange() {
    let continuingEducation = [];
    for (let ce of this.jobForm.value.continuing_education) {
      if (ce) continuingEducation.push(ce?.id);
    }

    let id = this.isNewJob == 'true' ? '' : this.jobForm.value.id;
    let companyId =
      this.isNewJob == 'true'
        ? this.selectedCompanies[0]?.id
        : this.jobDetails?.company?.id;
    let careType = [];
    for (let care of this.selectedCareTypeItems) {
      if (care) careType.push(care?.id);
    }

    let shiftHours = [];
    for (let shift of this.selectedShiftHoursItems) {
      shiftHours.push(shift.id);
    }

    let educationType = [];
    for (let education of this.selectedEducationItems) {
      educationType.push(education.id);
    }

    let workingType = [];
    for (let work of this.selectedScopeItems) {
      workingType.push(work.id);
    }

    let specificationCareType = [];
    for (let specification of this.specificationCareItems) {
      specificationCareType.push(specification.id);
    }

    let specialRequirementJobType = [];
    for (let specialRequirement of this.specialRequirementJobTypeItems) {
      specialRequirementJobType.push(specialRequirement.id);
    }

    const position = (this.selectedPosition || []).map(position => position.id);

    if (!companyId && this.isDublicate === 'false') {
      let data = {
        id: id,
        title: this.jobForm.value.title,
        health_facility_location_id: this.jobForm.value.facility,
        status: this.jobForm.value.status,
        position_other: (this.jobForm?.value?.position_other ?? '').trim(),
        shift_other: (this.jobForm?.value?.shift_other ?? '').trim(),
        note: (this.jobForm?.value?.note ?? '').trim(),
        internal_note:
          this.jobForm.value.internal_note == undefined ||
          this.jobForm.value.internal_note === null ||
          this.jobForm.value.internal_note == '' ||
          this.userRole === 3
            ? ''
            : this.jobForm.value.internal_note.trim(),
        care_type: careType,
        shift: shiftHours,
        education_type: educationType,
        working_type: workingType,
        specification_care_type: specificationCareType,
        special_requirements: specialRequirementJobType,
        url: (this.jobForm?.value?.url ?? '').trim(),
        min_salary: this.isShowSalary ? this.minSalary : null,
        max_salary: this.isShowSalary ? this.maxSalary : null,
        continuing_education: continuingEducation.map(e => [e, 1]),
        position,
      };
      return data;
    } else {
      let data = {
        company_id: companyId,
        title: this.jobForm.value.title,
        health_facility_location_id: this.jobForm.value.facility,
        status: this.jobForm.value.status,
        position_other: (this.jobForm?.value?.position_other ?? '').trim(),
        shift_other: (this.jobForm?.value?.shift_other ?? '').trim(),
        note: (this.jobForm?.value?.note ?? '').trim(),
        internal_note:
          this.jobForm.value.internal_note === null ||
          this.jobForm.value.internal_note == '' ||
          this.jobForm.value.internal_note === undefined
            ? ''
            : this.jobForm.value.internal_note.trim(),
        care_type: careType,
        shift: shiftHours,
        education_type: educationType,
        working_type: workingType,
        specification_care_type: specificationCareType,
        special_requirements: specialRequirementJobType,
        url: (this.jobForm?.value?.url ?? '').trim(),
        min_salary: this.isShowSalary ? this.minSalary : null,
        max_salary: this.isShowSalary ? this.maxSalary : null,
        continuing_education: continuingEducation.map(e => [e, 1]),
        position,
      };
      const compareRes =
        JSON.stringify(data) == JSON.stringify(this.dublicateDetails);

      if (compareRes === true) {
        this.isValueChange = false;
      } else {
        this.isValueChange = true;
      }
      return data;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openJobHistory(job_id) {
    console.log(job_id);
  }
}
