import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConstantsService } from './services/constants.service';
import { JobService } from './services/job.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'healthcare-front';
  isLoginPage = false;
  isMobile = false;

  constructor(
    public router: Router,
    private deviceService: DeviceDetectorService,
    public constantsService: ConstantsService,
    private jobService: JobService
  ) {}

  checkIsLogin($event) {
    this.isLoginPage = false;
  }

  ngOnInit() {
    this.isLoginPage = true;

    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile === true) {
      this.router.navigate(['mobile']);
    } else if (window.location.pathname.includes('login')) {
      this.isLoginPage = false;
    }
    this.constantsService.fetchConstants();
    this.jobService.getAgent();
  }
}
