export const environment = {
  firebase: {
    projectId: 'pflegepiraten-359711',
    appId: '1:766892621034:web:9d3e570d03a34c10bfeb93',
    storageBucket: 'pflegepiraten-359711.appspot.com',
    apiKey: 'AIzaSyDPoEEFkbOC7YPoCAb7vJqvBXT-ixgj0Ts',
    authDomain: 'pflegepiraten-359711.firebaseapp.com',
    messagingSenderId: '766892621034',
    measurementId: 'G-YRV89Q0EJH',
  },
  production: false,
  BASE_URL: 'https://pflegepiraten-dev.fly.dev/',
  PATH: 'api/v1/',
  laenkApps: {
    applicant: {
      url: 'https://jobs.dev.laenk.de',
    },
    company: {
      url: 'https://app.dev.laenk.de',
    },
  },
};
