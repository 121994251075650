import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SheetComponent } from './sheet/sheet.component';

@Component({
  selector: 'app-row-selecter',
  templateUrl: './row-selecter.component.html',
  styleUrls: ['./row-selecter.component.scss'],
})
export class RowSelecterComponent implements OnInit {
  @Input('uniqueId') key: string;
  @Input('allRows') allRows: { key: string; name: string }[];
  @Input('selectedRows') selectedRows: string[];
  constructor(private bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    if (localStorage.getItem(this.key) != null) {
      this.selectedRows.length = 0;
      JSON.parse(localStorage.getItem(this.key)).forEach(element => {
        if (
          this.key === 'applierTable_V1' ||
          this.key === 'jobTable' ||
          (this.key === 'matchTable_V4' &&
            ['caretype', 'created', 'content'].indexOf(element) === -1)
        ) {
          this.selectedRows.push(element);
        }
      });
    } else {
      localStorage.setItem(this.key, JSON.stringify(this.selectedRows));
    }
  }

  openComponentSheetMenu() {
    this.bottomSheet.open(SheetComponent, {
      panelClass: 'custom-width',
      data: { rows: this.allRows, selected: this.selectedRows, key: this.key },
    });
  }
}
