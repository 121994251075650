<h1 mat-dialog-title>Edit Interview Slot</h1>

<form mat-dialog-content [formGroup]="interview" (ngSubmit)="onSubmit()">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="selectedDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Time</mat-label>
      <input
        matInput
        type="time"
        formControlName="selectedTime"
        placeholder="Interview Time"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button type="submit" mat-button [disabled]="interview.invalid">
      Save Changes
    </button>
  </div>
</form>
