import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileActiveGuard implements CanActivate {
  isMobile = false;
  constructor(private deviceService: DeviceDetectorService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.isMobile = this.deviceService.isMobile();
   
    if (this.isMobile === true) {
      this.router.navigate(['mobile']);
      return false
    }
    else if (window.location.pathname.includes('mobile')) {
      return false;
    }
    return true;
  }
  
}
