<div
  *ngFor="let history of histories | keyvalue : originalOrder"
  class="histories"
>
  <div class="card-title">
    Bewerbung:
    <time [datetime]="history.key">
      {{ history.key | date : 'dd.MM.yyyy' }}
    </time>
  </div>
  <mat-card *ngFor="let item of history.value; first as isFirst">
    <div class="card-image">
      <ng-template
        [ngIf]="item?.old_status !== item?.new_status"
        [ngIfElse]="changes"
      >
        <mat-icon *ngIf="item?.old_status !== item?.new_status">
          query_builder
        </mat-icon>
      </ng-template>

      <ng-template #changes>
        <mat-icon> published_with_changes </mat-icon>
      </ng-template>
    </div>
    <div class="content">
      <mat-card-subtitle>
        {{ item?.created_at | date : 'dd.MM.yyyy, HH:mm' }}
        |
        <span
          matTooltip="erstellt von"
          *ngIf="item?.actor == 'SYSTEM'; else actor"
        >
          {{ 'System' }}
        </span>
        <ng-template #actor>
          <span>
            {{ item?.user?.first_name }}
            {{ item?.user?.last_name }}
            ({{ getActorLabel(item?.actor)?.label ?? '' }})
          </span>
        </ng-template>
      </mat-card-subtitle>

      <mat-card-content
        *ngIf="item.type === 'CRUD' && item?.details?.changed; else status"
      >
        Die Statusdetails wurden bearbeitet
        <ul>
          <ng-container
            class="changed_field"
            *ngFor="let change of item?.details?.changed"
          >
            <li *ngIf="getFieldLabel(change)">
              <i>
                {{ getFieldLabel(change) }}
              </i>
              von

              <span>
                "{{
                  change?.old || (change?.old ?? []).length
                    ? change?.old
                    : 'keine Angabe'
                }}" zu "{{
                  change?.new || (change?.new ?? []).length
                    ? change?.new
                    : 'keine Angabe'
                }}"
              </span>
            </li>
          </ng-container>
        </ul>
      </mat-card-content>

      <ng-template #status>
        <mat-card-content *ngIf="item?.old_status !== item?.new_status">
          {{ statusChangeLabel(item) }}
        </mat-card-content>
      </ng-template>
    </div>
  </mat-card>
</div>
