import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-user',
  templateUrl: './mobile-user.component.html',
  styleUrls: ['./mobile-user.component.scss']
})
export class MobileUserComponent implements OnInit {
  [x: string]: any;

  constructor() { }

  ngOnInit(): void {
  }

}
