import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Auth } from '@angular/fire/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isLoginPage = false;
  @Output() isLogin = new EventEmitter<any>();
  isAjgentLogin = false;
  isACuserLogin = false;
  isMobile = false;
  constructor(
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private deviceService: DeviceDetectorService,
    public auth: Auth
  ) {
    this.router.events.subscribe(event => {
      if (
        this.router.routerState.snapshot.url == '/login' ||
        this.router.routerState.snapshot.url == '/register' ||
        this.router.routerState.snapshot.url.includes('/login') ||
        this.router.routerState.snapshot.url.includes('/alogin')
      ) {
        this.isLoginPage = false;
      } else {
        this.isLoginPage = true;
      }
      if (localStorage.getItem('agentData')) {
        this.isAjgentLogin = true;
      }
      if (localStorage.getItem('companyData')) {
        this.isACuserLogin = true;
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  changeLang(event) {
    this.translateConfigService.changeLanguage(event.value);
  }

  logout() {
    this.auth
      .signOut()
      .then(() => {
        this.isLogin.emit(false);
        localStorage.removeItem('msalAccount');
        localStorage.removeItem('agentData');
        this.isAjgentLogin = false;
        this.router.navigate(['alogin']);
      })
      .catch(error => console.log(error));
  }
}
