import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { InterviewSlot } from 'src/app/type';
import { MatchInterviewDialogComponent } from './dialog/match-interview-dialog/match-interview-dialog.component';

@Component({
  selector: 'app-match-interview',
  templateUrl: './match-interview.component.html',
  styleUrls: ['./match-interview.component.scss'],
})
export class MatchInterviewComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private historyMatchService: HistoryMatchService
  ) {}
  @Input() match;
  interviews: InterviewSlot[] = [];

  formatDate(date: Date) {
    return moment(date).format('dddd, MMM DD, YYYY, HH:mm');
  }

  fetchMatchInterviews(match) {
    this.historyMatchService
      .getMatchInterviewSlots(match.extID)
      .pipe(take(1))
      .subscribe((response: InterviewSlot[]) => {
        this.interviews = response;
      });
  }

  deleteInterview(interview: InterviewSlot) {
    this.historyMatchService
      .deleteInterviewSlots(interview.external_id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          const idx = this.interviews.findIndex(
            i => i.external_id === interview.external_id
          );
          if (idx == -1) return;
          this.interviews.splice(idx, 1);
          this.interviews = [...this.interviews];
          this._snackBar.open('interview deleted', 'OK', {
            duration: 4000,
          });
        },
        error: e => {
          this._snackBar.open('Something Went wrong', 'OK', { duration: 4000 });
        },
      });
  }

  openDialog(interview: InterviewSlot) {
    const dialogRef = this.dialog.open(MatchInterviewDialogComponent, {
      data: { interview: interview, match: this.match },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      const idx = this.interviews.findIndex(
        i => i?.external_id === result?.external_id
      );

      if (idx == -1) {
        this.interviews.push(result);
        return;
      }
      this.interviews[idx] = result;
    });
  }

  ngOnInit(): void {
    this.fetchMatchInterviews(this.match);
  }
}
