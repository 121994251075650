<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer
    #drawer
    class="notification-sidenav"
    id="notification-sidenav"
    position="end"
    [mode]="'side'"
    (closedStart)="startClose()"
  >
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)"
    >
      <mat-tab label="Bewerber">
        <app-applier-sidebar
          *ngIf="drawer.opened && selectedTab?.selected[0] == 0"
          [applierId]="selectedApplierId"
          (updateAndClose)="updateAndClose($event)"
          (applierDataEvent)="setApplierStatus($event)"
          (update)="update($event)"
          (doClose)="closeDrawer()"
          (handleError)="handleError()"
        ></app-applier-sidebar>
      </mat-tab>
      <mat-tab label="Verlauf">
        <app-history-sidebar
          *ngIf="drawer.opened && selectedTab?.selected[0] == 1"
          [applierId]="selectedApplierId"
          [applier]="applierData"
          (refreshApplier)="refreshTable()"
          (doClose)="closeDrawer()"
        ></app-history-sidebar>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>

  <mat-drawer
    id="match-drawer"
    #matchDrawer
    [mode]="'over'"
    (openedChange)="closeMatchDrawer($event)"
  >
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedMatchDrawerTab.selected"
      (selectedIndexChange)="selectedMatchDrawerTab.select($event)"
    >
      <mat-tab label="Unternehmen">
        <app-companysidebar
          *ngIf="matchDrawer.opened"
          [editMode]="false"
          [companyId]="match?.company?.id"
          [isNewCompany]="'false'"
        >
        </app-companysidebar>
      </mat-tab>

      <mat-tab label="Job">
        <app-job-sidebar
          *ngIf="matchDrawer.opened"
          [editMode]="false"
          [jobId]="match?.job?.id"
          [dropdownCompanies]="dropdownCompanies"
          [companyId]="match?.company?.id"
          [isDublicate]="false"
        >
        </app-job-sidebar>
      </mat-tab>

      <mat-tab label="Verlauf">
        <app-match-history
          *ngIf="matchDrawer.opened"
          [applierId]="match.applier.id"
          [match]="match"
          [agents]="dropdownAgents"
        >
        </app-match-history>
      </mat-tab>

      <mat-tab label="Interviews">
        <app-match-interview *ngIf="matchDrawer.opened" [match]="match">
        </app-match-interview>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>

  <!-- Filter Start  -->
  <div>
    <div class="row filters-row actions-bar">
      <div *ngIf="!isAddressActive" class="col filters-col">
        <mat-form-field>
          <mat-label translate>home.search_placeholder</mat-label>

          <input
            matInput
            [(ngModel)]="searchText"
            (input)="search()"
            placeholder="{{ 'home.search_placeholder_value' | translate }}"
            #input
          />
        </mat-form-field>
      </div>

      <div *ngIf="isAddressActive" class="col filters-col">
        <mat-form-field appearance="legacy">
          <mat-label translate>home.search_placeholder</mat-label>
          <input
            matInput
            ngx-google-places-autocomplete
            [options]="options"
            [placeholder]="'home.search_placeholder_value' | translate"
            #input
            (onAddressChange)="AddressChange($event)"
          />
        </mat-form-field>
      </div>

      <mat-checkbox
        [(ngModel)]="isActiveproximity"
        (change)="getActiveProximity()"
      >
        {{ 'jobs.activate_proximity' | translate }}
      </mat-checkbox>

      <mat-checkbox
        [(ngModel)]="needActionChecked"
        (change)="getActionNeeded()"
        translate
        >{{ 'home.search_need_action' | translate }}</mat-checkbox
      >

      <div class="col filters-col filters-col--right">
        <button
          mat-flat-button
          class="btn"
          style="margin-bottom: 15px"
          (click)="ShowFilter = !ShowFilter"
        >
          {{ 'jobs.more_filters' | translate }}
        </button>
      </div>
    </div>

    <div
      class="row filters-row expand"
      [@detailExpand]="ShowFilter ? 'expanded' : 'collapsed'"
    >
      <div class="col filters-col">
        <ng-multiselect-dropdown
          placeholder="status"
          class="multiselect-dropdown"
          (onSelect)="filterApplicants()"
          (onDeSelect)="filterApplicants()"
          [settings]="dropdownSettings"
          [data]="status"
          [(ngModel)]="selectedStatus"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col">
        <ng-multiselect-dropdown
          (onSelect)="filterApplicants()"
          (onDeSelect)="filterApplicants()"
          class="multiselect-dropdown"
          placeholder="Pflegeart"
          [settings]="dropdownSettings"
          [data]="constants.getList('care_type') | async"
          [(ngModel)]="selectedCareTypes"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col">
        <ng-multiselect-dropdown
          (onSelect)="filterApplicants()"
          (onDeSelect)="filterApplicants()"
          class="multiselect-dropdown"
          placeholder="Ausbildung"
          [settings]="dropdownSettings"
          [data]="constants.getList('education_type') | async"
          [(ngModel)]="selectedEducations"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col">
        <ng-multiselect-dropdown
          (onSelect)="filterApplicants()"
          (onDeSelect)="filterApplicants()"
          class="multiselect-dropdown"
          placeholder="Herkunft"
          [settings]="dropdownSettings"
          [data]="constants.getList('reference_type') | async"
          [(ngModel)]="selectedReferenceBy"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col">
        <mat-form-field>
          <mat-label translate>analytics.date</mat-label>
          <input
            autocomplete="off"
            type="text"
            ngxDaterangepickerMd
            matInput
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            placeholder="Select please..."
            [autoApply]="false"
            [closeOnAutoApply]="true"
            [showClearButton]="true"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [(ngModel)]="selectedDateRange"
            [locale]="locale"
            (change)="onSelect(selectedDateRange)"
          />
        </mat-form-field>
      </div>
    </div>
    <!--Start with table-->
    <mat-table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="mat-elevation-z8 table-hover table-applicant"
      matSort
      (matSortChange)="sortBy($event)"
      matSortActive="date"
      matSortStart="asc"
      matSortDisableClear
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" class="table__actions">
          <mat-slide-toggle
            class="action-icon"
            matTooltip="{{ row.mail_toggle === 1 ? 'Mail' : 'Whatsapp' }}"
            [checked]="row.mail_toggle === 1 ? false : true"
            (click)="$event.stopPropagation()"
            (change)="toggles($event, row)"
            color="primary"
          >
          </mat-slide-toggle>

          <mat-icon class="action-icon" (click)="toggle($event, row?.id, 1)"
            >history</mat-icon
          >

          <mat-icon
            class="action-icon"
            matBadge="{{ (row?.user?.files ?? []).length }}"
            matBadgeHidden="{{
              (row?.user?.files ?? []).length ? false : true
            }}"
            matBadgeSize="small"
            [matMenuTriggerFor]="fileUplods"
            (click)="$event.stopPropagation()"
            >download</mat-icon
          >
          <mat-menu
            #fileUplods="matMenu"
            xPosition="before"
            yPosition="below"
            class="files"
          >
            <button mat-menu-item (click)="download(row)">
              <span translate>home.short_profile</span>
            </button>
            <div
              mat-menu-item
              *ngFor="let file of row?.user?.files; let index = index"
            >
              <a href="{{ file.file_url }}" target="_blank">
                {{ file.type | constantLabel : 'user_file_type' | async }}
              </a>
            </div>
          </mat-menu>

          <mat-icon class="action-icon" (click)="toggle($event, row?.id, 0)"
            >edit</mat-icon
          >
          <mat-icon
            class="action-icon"
            (click)="viewApplicant(row)"
            matTooltip="Bewerber ansehen"
            >link</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header="name">
          {{ 'home.col_name' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <tag *ngIf="element.duplicate" class="table-badge" [active]="true"
            >Duplikat</tag
          >{{ element?.user?.first_name }} {{ element?.user?.last_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header="created_at">
          {{ 'home.col_date' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          {{ element?.created_at | date : 'dd.MM.yyyy HH:mm' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="education">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_education
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <ng-container *ngIf="element?.education?.length">
            <ng-container *ngFor="let edu of element?.education">
              <tag>
                {{ edu | constantLabel : 'education_type' | async }}
              </tag>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!element?.education?.length">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="continous_education">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_continuing_educations
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <tag
            *ngFor="let ce of element?.continuing_education"
            [color]="'secondary'"
          >
            {{ ce | constantLabel : 'continuing_education_type' | async }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="care_type">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_care_type
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <ng-container *ngIf="element?.care_type?.length > 0">
            <tag *ngFor="let ct of element?.care_type">
              {{ ct | constantLabel : 'care_type' | async }}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.care_type?.length == 0">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shift_hour">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_shift_hour
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <ng-container *ngIf="element?.shift_hour?.length">
            <tag *ngFor="let shift of element?.shift_hour">
              {{ shift | constantLabel : 'shift_hour_type' | async }}
            </tag>
          </ng-container>
          <ng-container
            *ngIf="element.shift_other != null && element.shift_other != ''"
          >
            <tag>{{ element.shift_other }} </tag>
          </ng-container>
          <ng-container
            *ngIf="
              element?.shift_hour?.length == 0 && element?.shift_others == ''
            "
          >
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scope">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_scope
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          <tag *ngFor="let wt of element?.working_type" [color]="'secondary'">
            {{ wt | constantLabel : 'shift_time_type' | async }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header="city">
          {{ 'home.col_location' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          {{ element?.location }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>
          {{ 'home.col_status' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [class.example-expanded-row]="expandedElement === element"
        >
          {{ element?.status | constantLabel : 'applier_status' | async }}

          <div class="tags">
            <tag *ngFor="let flag of element?.flags" [color]="'secondary'">
              {{ flag.flag | constantLabel : 'applier_flag' | async }}
            </tag>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <app-expandable-row
              *ngIf="element == expandedElement"
              [applierId]="element.id"
              [lat]="element.latitude"
              [lng]="element.longitude"
              (openMatchDrawerEvent)="openMatchDrawer($event)"
            ></app-expandable-row>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="columnsToDisplay"
        class="elements--{{ columnsToDisplay.length }}"
      ></mat-header-row>
      <mat-row
        (click)="expandedElement = expandedElement === element ? null : element"
        *matRowDef="let element; columns: columnsToDisplay"
        id="{{ element.id }}_match"
        [class.interested]="element?.is_interested_in_a_match"
        [class.statusNeed_action]="
          element?.need_action && element?.status_log?.length
        "
        class="{{
          element?.need_action
            ? 'isActionNeeded example-element-row'
            : 'example-element-row'
        }} {{ element.id }}_match elements--{{ columnsToDisplay.length }}"
      >
      </mat-row>
      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></mat-row>
    </mat-table>
    <div
      class="mat-elevation-z8 paginator-row"
      *ngIf="dataSource.page$ | async"
    >
      <div class="progress-bar">
        <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
        </mat-progress-bar>
      </div>
      <mat-paginator
        *ngIf="dataSource.page$ | async as page"
        [length]="page.totalElements"
        [pageSize]="10"
        [showFirstLastButtons]="true"
        [hidePageSize]="true"
        (page)="dataSource.fetch($event.pageIndex)"
        [pageIndex]="page.number"
        (page)="currentPage = $event.pageIndex == 0 ? 1 : $event.pageIndex"
      >
      </mat-paginator>
    </div>
  </div>
  <div
    *ngIf="(dataSource.loading$ | async) !== false && !currentPage"
    class="skeleton-loader"
  >
    <ngx-skeleton-loader
      count="11"
      appearance="line"
      [theme]="{ 'height.px': 35 }"
    ></ngx-skeleton-loader>
  </div>
  <app-row-selecter
    [uniqueId]="columnKey"
    [allRows]="columnsAll"
    [selectedRows]="columnsToDisplay"
  ></app-row-selecter>
</mat-drawer-container>
