<div class="interviews">
  <div class="add">
    <button mat-raised-button color="primary" (click)="openDialog(null)">
      <mat-icon> add </mat-icon>
      Schedule Interview
    </button>
  </div>

  <div *ngFor="let interview of interviews" class="interview">
    <div class="interview-time">
      <mat-icon class="icon"> schedule </mat-icon>
      <span>
        {{ formatDate(interview.schedule_time) }}
      </span>
    </div>
    <div class="interview-actions">
      <button mat-icon-button (click)="openDialog(interview)">
        <mat-icon> edit </mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="deleteInterview(interview)">
        <mat-icon> delete </mat-icon>
      </button>
    </div>
  </div>
</div>
