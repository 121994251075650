import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-kolkov-editor',
  templateUrl: './kolkov-editor.component.html',
  styleUrls: ['./kolkov-editor.component.scss'],
})
export class KolkovEditorComponent implements OnInit {
  constructor() {}
  @Input() formControlName: string; 
  @Input() parentForm;
  @Output() update = new EventEmitter<any>();

  htmlContent = '';
  htmlContentWithoutStyles = '';
  showHTML() {
    this.update.emit(this.parentForm.value);

    // this.htmlContentWithoutStyles =
    //   document.getElementById('htmlDiv').innerHTML;
  }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '7.5rem',
    minHeight: '3rem',
    // placeholder: 'Beschreibung',
    translate: 'de',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'redo',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'fontName',
        'backgroundColor',
        'textColor',
        'insertImage',
        'insertVideo',
        'fontSize',
        'horizontalLine',
        'insertHorizontalRule',
        'toggleEditorMode',
      ],
    ],
  };

  ngOnInit(): void {}
}
