<div *ngIf="applierId" class="wrapper">
  <div class="flags-wrapper">
    <ng-multiselect-dropdown
      placeholder="{{ 'match.flags' | translate }}"
      [settings]="dropdownSettings"
      [(ngModel)]="selectedFlag"
      [data]="constants.getList('match_flag') | async"
      (onSelect)="onFlagSelect($event)"
      (onDeSelect)="onFlagDeSelect($event)"
      #multiSelect
      style="display: block; width: 50%; margin-block-start: 10px"
    >
    </ng-multiselect-dropdown>

    <div
      [class.opacify]="companyFeedbackCount(match?.company_feedback) === 0"
      [ngxTippy]="
        companyFeedbackCount(match?.company_feedback) !== 0
          ? tooltipHTMLTemplate
          : null
      "
      tippyClassName="match_company_feedback"
      [tippyProps]="{
        placement: 'bottom',
        theme: 'light'
      }"
    >
      {{ companyFeedbackCount(match?.company_feedback) }} Statusdetails
      <mat-icon>info_outline</mat-icon>
    </div>

    <template #tooltipHTMLTemplate>
      <div>
        <b *ngIf="match.company_feedback.interview_date">{{
          'match-history.statusDetails.interviewDate' | translate
        }}</b>
        <p>
          <span>
            {{ match.company_feedback.interview_date | date : 'dd.MM.yyyy' }}
          </span>
        </p>
      </div>

      <div>
        <b *ngIf="match.company_feedback.shadowing_date">{{
          'match-history.statusDetails.shadowingDate' | translate
        }}</b>
        <p>
          <span>{{
            match.company_feedback.shadowing_date | date : 'dd.MM.yyyy'
          }}</span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.salary">
        <b class="">{{ 'match-history.statusDetails.salary' | translate }}</b>
        <p>
          <span>{{ match.company_feedback.salary }}</span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.start_date">
        <b>{{ 'match-history.statusDetails.startDate' | translate }}</b>
        <p>
          <span>
            {{ match.company_feedback.start_date | date : 'dd.MM.yyyy' }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.terminated_by">
        <b>{{ 'match-history.statusDetails.terminatedBy' | translate }}</b>
        <p>
          <span>
            {{ match.company_feedback.terminated_by }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.termination_date">
        <b>{{ 'match-history.statusDetails.terminationDate' | translate }}</b>
        <p>
          <span>
            {{
              match.company_feedback.termination_date
                | localizedDate : 'dd.MM.yyyy'
            }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.termination_reason">
        <b>{{ 'match-history.statusDetails.terminationReason' | translate }}</b>
        <p>
          {{ match.company_feedback.termination_reason }}
        </p>
      </div>

      <div *ngIf="match.company_feedback.rejected_by">
        <b>{{ 'match-history.statusDetails.rejectedBy' | translate }}</b>
        <p class="card-line-value capitalize">
          <span>
            {{ match.company_feedback.rejected_by }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.rejection_reason">
        <b class="">{{
          'match-history.statusDetails.rejectionReason' | translate
        }}</b>
        <p>
          {{ match.company_feedback.rejection_reason }}
        </p>
      </div>
    </template>
  </div>

  <form class="company-status" (ngSubmit)="updateMatchStatus()">
    <div class="phase_wrapper">
      <mat-form-field>
        <mat-label>Phase</mat-label>
        <mat-select
          [(ngModel)]="selectedMatchStage"
          (selectionChange)="resetSelectedDate()"
          name="Status"
        >
          <mat-option
            *ngFor="let stati of constants.getList('match_stage') | async"
            [value]="stati.id"
          >
            {{ stati?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="interview">
        <mat-form-field
          *ngIf="
            ['ACCEPTED', 'INTERVIEW', 'SHADOWING'].includes(selectedMatchStage)
          "
        >
          <input
            matInput
            [ngxMatDatetimePicker]="picker"
            placeholder="Datum auswählen"
            [(ngModel)]="selectedDate"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="disabled"
            name="datetimepicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker
            [showSpinners]="true"
            [showSeconds]="false"
            [stepHour]="1"
            [stepMinute]="1"
            [touchUi]="true"
            [color]="color"
            [enableMeridian]="false"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-checkbox
          *ngIf="['INTERVIEW', 'SHADOWING'].includes(selectedMatchStage)"
          [(ngModel)]="interviewTookPlace"
          name="interviewTookPlace"
          >{{
            selectedMatchStage === 'INTERVIEW' ? 'Interview' : 'Hospitation'
          }}
          nicht stattgefunden</mat-checkbox
        >
      </div>

      <button
        type="submit"
        mat-flat-button
        color="primary"
        style="margin-left: 16px; height: 2rem"
      >
        {{ 'match-history.btn_send' | translate }}
      </button>
    </div>
  </form>
  <div style="display: flex; gap: 1rem">
    <mat-checkbox
      [(ngModel)]="need_action"
      (change)="updateNeedAction($event.checked)"
      >Need Action?</mat-checkbox
    >

    <mat-checkbox
      [(ngModel)]="protected"
      (change)="toggleProtectedOnMatch($event.checked)"
      >Protected Match?</mat-checkbox
    >
  </div>

  <mat-divider></mat-divider>

  <form
    class="form"
    style="margin-top: 50px"
    [formGroup]="historyForm"
    (ngSubmit)="submitHistory()"
  >
    <div class="form__content">
      <!--Content field-->
      <div class="form--content">
        <mat-form-field>
          <mat-label>Kurzbeschreibung</mat-label>
          <input matInput formControlName="content" />
        </mat-form-field>
      </div>

      <!--Templates-->
      <div class="form--template">
        <mat-form-field>
          <mat-label>Template</mat-label>
          <mat-select formControlName="status_id">
            <mat-option
              *ngFor="let stati of status"
              [value]="stati.id"
              (click)="updateDate(stati?.due_days, $event)"
            >
              {{ stati?.status_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--due date-->
      <div class="form--due">
        <mat-form-field>
          <mat-label>nächste Aktion</mat-label>
          <input
            matInput
            formControlName="due_date"
            [matDatepicker]="picker2"
            [disabled]="!isDueDate"
            (ngModelChange)="onDueDateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Assigned to -->
      <div class="form--assigned">
        <ng-multiselect-dropdown
          placeholder="Verantwortlicher"
          [settings]="singleSelectDropdownSettings"
          [data]="agents"
          (onSelect)="onAgentChange()"
          (onDeSelect)="onAgentChange()"
          formControlName="assigned_to"
        >
        </ng-multiselect-dropdown>
      </div>

      <button
        class="form--submit"
        type="submit"
        mat-flat-button
        color="primary"
      >
        {{ 'match-history.btn_send' | translate }}
      </button>
    </div>

    <div>
      <p
        *ngIf="historyForm.errors?.contentOrStatusRequired"
        class="error-message"
      >
        If both due date and assigned to are provided, at least one of content
        or status ID is required.
      </p>

      <p
        *ngIf="historyForm.errors?.formCannotBeBlank && formSubmitted"
        class="error-message"
      >
        Form cannot be blank
      </p>
    </div>
  </form>

  <mat-divider></mat-divider>

  <app-add-label [matchId]="match?.id" [labelIds]="labelIds"></app-add-label>

  <mat-divider></mat-divider>

  <div
    *ngFor="let history of histories | keyvalue : originalOrder"
    class="histories"
  >
    <div class="card-title">
      Bewerbung:
      <time [datetime]="history.key">
        {{ history.key | date : 'dd.MM.yyyy' }}
      </time>
    </div>
    <mat-card *ngFor="let item of history.value; first as isFirst">
      <div
        class="card-image"
        [class.card-image--flag]="item.type === 'FLAG'"
        [class.card-image--communication]="item.type === 'COMMUNICATION'"
        [class.card-image--note]="
          !item?.due_date && !(item?.old_status !== item?.status)
        "
        [class.card-image--status-change]="item?.old_status !== item?.status"
        [class.card-image--changes]="item?.details?.changed"
      >
        <mat-icon
          class="whatsapp-icon"
          *ngIf="item?.details?.channel_id === '1'; else statusIcon"
        >
        </mat-icon>

        <ng-template #statusIcon>
          <mat-icon *ngIf="item?.old_status !== item?.status; else flagIcon">
            query_builder
          </mat-icon>
        </ng-template>

        <ng-template #flagIcon>
          <mat-icon *ngIf="item.type === 'FLAG'; else communicationIcon">
            flag
          </mat-icon>
        </ng-template>

        <ng-template #communicationIcon>
          <mat-icon *ngIf="item.type === 'COMMUNICATION'; else changesIcon">
            forum
          </mat-icon>
        </ng-template>

        <ng-template #changesIcon>
          <mat-icon *ngIf="item?.details?.changed; else interview_date">
            published_with_changes
          </mat-icon>
        </ng-template>

        <ng-template #interview_date>
          <mat-icon *ngIf="item?.details?.interview; else noDueDate">
            interpreter_mode
          </mat-icon>
        </ng-template>

        <ng-template #noDueDate>
          <mat-icon *ngIf="!item?.due_date; else elseBlock">
            note_alt
          </mat-icon>
        </ng-template>

        <ng-template #elseBlock>
          <mat-icon *ngIf="item?.details?.channel_id; else default"
            >{{ item?.details?.channel_id | HistoryMatchChannelAbbreviations }}
          </mat-icon>
          <ng-template #default>
            <mat-icon> work_history </mat-icon>
          </ng-template>
        </ng-template>
      </div>
      <div class="content">
        <mat-card-subtitle class="no-margin">
          {{ item?.created_at | date : 'dd.MM.yyyy, HH:mm' }}
          |
          <span
            matTooltip="erstellt von"
            *ngIf="item?.actor == 'SYSTEM'; else actor"
          >
            {{ 'System' }}
          </span>
          <ng-template #actor>
            <ng-container
              *ngIf="
                item.interview && item.actor === 'COMPANY';
                else noInterviewActor
              "
            >
              Unternehmen
            </ng-container>
          </ng-template>

          <ng-template #noInterviewActor>
            <span>
              {{ item?.created_by?.first_name }}
              {{ item?.created_by?.last_name }}
              ({{ getActorLabel(item?.actor)?.label ?? '' }})
            </span>
          </ng-template>
        </mat-card-subtitle>
        <mat-card-content *ngIf="item.type === 'FLAG'; else fieldChange">
          {{ flagLabel(item) }}
        </mat-card-content>

        <ng-template #fieldChange>
          <mat-card-content *ngIf="item?.details?.changed; else status">
            Die Statusdetails wurden bearbeitet
            <ul>
              <ng-container
                class="changed_field"
                *ngFor="let change of item?.details?.changed"
              >
                <li *ngIf="getFieldLabel(change)">
                  <i>
                    {{ getFieldLabel(change) }}
                  </i>
                  von

                  <span
                    *ngIf="
                      ['start_date', 'interview_date'].includes(change.field);
                      else notInterviewType
                    "
                  >
                    "{{
                      change?.old
                        ? (change?.old | date : 'dd.MM.yyyy HH:mm')
                        : 'keine Angabe'
                    }}" zu "{{
                      change?.new
                        ? (change?.new | date : 'dd.MM.yyyy HH:mm')
                        : 'keine Angabe'
                    }}"
                  </span>

                  <ng-template #notInterviewType>
                    <span
                      *ngIf="change?.field != 'company_note'; else companyNote"
                    >
                      "{{ change?.old ? change?.old : 'keine Angabe' }}" zu "{{
                        change?.new ? change?.new : 'keine Angabe'
                      }}"
                    </span>
                  </ng-template>

                  <ng-template #companyNote>
                    <span
                      class="changed_field--company-note"
                      [innerHTML]="change?.old ?? 'keine Angabe'"
                    ></span>
                    zu
                    <span
                      class="changed_field--company-note"
                      [innerHTML]="change?.new ?? 'keine Angabe'"
                    ></span>
                  </ng-template>
                </li>
              </ng-container>
            </ul>
          </mat-card-content>
        </ng-template>

        <ng-template #status>
          <mat-card-content
            *ngIf="item?.old_status !== item?.status; else communication"
          >
            {{ statusChangeLabel(item) }}
          </mat-card-content>
        </ng-template>

        <ng-template #communication>
          <mat-card-content
            *ngIf="item.type === 'COMMUNICATION'; else interview"
          >
            Eine E-mail / WhatsApp Nachricht wurde verschickt:
            {{ communcationLabel(log?.details) }}
          </mat-card-content>
        </ng-template>

        <ng-template #interview>
          <mat-card-content *ngIf="item?.details?.interview; else default">
            Interview Details geändert
          </mat-card-content>
        </ng-template>

        <div *ngIf="item?.details?.status_id; else default">
          <mat-card-content
            class="{{ item?.content ? 'no-margin' : 'm3-margin' }}"
            >{{ item?.status_log?.status_name }}
          </mat-card-content>
        </div>

        <div class="note" *ngIf="item?.note">"{{ item?.note }}"</div>

        <mat-card-content
          class="mat-card-content--task"
          *ngIf="item?.assigned_to"
        >
          <div class="text">
            <span matTooltip="Ablaufdatum" *ngIf="item?.due_date">
              Fällig am: {{ item?.due_date | date : 'dd.MM.yyyy' }}</span
            >
            Verantwortlich: {{ item?.assigned_to?.first_name }}
            {{ item?.assigned_to?.last_name }}
          </div>
          <div class="action">
            <button
              *ngIf="!item.completed_at"
              mat-mini-fab
              (click)="completeTask(history.key, item.id, true)"
              color="basic"
              aria-label="Work item uncompleted"
            >
              <mat-icon class="unchecked">radio_button_unchecked</mat-icon>
            </button>
            <button
              *ngIf="item.completed_at"
              (click)="completeTask(history.key, item.id, false)"
              mat-mini-fab
              color="basic"
              aria-label="Work item completed"
            >
              <mat-icon class="checked">check_circle</mat-icon>
            </button>
          </div>
        </mat-card-content>

        <mat-card-subtitle class="less-margin">
          <span
            matTooltip="Ablaufdatum"
            *ngIf="item?.due_date && !item?.assigned_to"
            >{{ item?.due_date | date : 'dd.MM.yyyy' }}</span
          >

          <div *ngIf="item?.details?.interview">
            <span *ngFor="let change of item?.details?.interview">
              <ng-container *ngIf="change.field === 'company_accepted_at'">
                Unternehmen bestätigt Termin:
              </ng-container>

              <ng-container *ngIf="change.field === 'user_accepted_at'">
                Bewerber bestätigt Termin:
              </ng-container>

              <ng-container *ngIf="change.field === 'schedule_time'">
                Bewerber schlägt Termin vor:
              </ng-container>
              {{ item?.interview.schedule_time | date : 'dd.MM.yyyy HH:mm' }}
            </span>
          </div>
        </mat-card-subtitle>
        <mat-card-content class="less-margin">
          {{ item?.status?.status_name }}
          <ng-container *ngIf="item.costum_date"
            >({{ item.costum_date | date : 'dd.MM.yyyy, HH:mm' }})</ng-container
          >
        </mat-card-content>
      </div>
    </mat-card>
  </div>

  <ng-container *ngIf="showSpinner">
    <ngx-skeleton-loader
      count="7"
      appearance="line"
      [theme]="{ 'height.px': 50 }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngIf="historiesIsEmpty">
    <h3 class="drawer-title" style="text-align: center; margin: 20px">
      {{ 'match-history.no_record' | translate }}
    </h3>
  </ng-container>
</div>
