import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { Sort } from '@angular/material/sort';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';

import { JobService } from 'src/app/services/job.service';
import { Constants } from '../../models/abbreviation.model';
import { JobDeleteDialogComponent } from '../common/job-delete-dialog/job-delete-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { AgentUser } from 'src/app/type';

@Component({
  selector: 'app-job-home',
  templateUrl: './job-home.component.html',
  styleUrls: ['./job-home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class JobHomeComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  companies = [];
  ShowFilter: boolean = false;
  companyId: number;
  jobId: number;
  tabId: number;
  isCopy: number;
  postcode: number;
  isJobUpdated: any;
  currentUrl: string;
  previousUrl: string;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isNewJob = false;
  isDublicate: boolean = false;
  parentCompanyId: 0;
  isActiveJobs = false;
  isActiveproximity = false;
  isAddressActive = false;
  statusData: any;
  lat: any = '';
  lng: any = '';
  options = {
    types: ['geocode'],
    componentRestrictions: {
      country: ['de'],
    },
  };
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchChanged$: Subject<string> = new Subject<string>();
  searchSubcription: Subscription;
  selectedTab = new SelectionModel(false, [0]);
  sorting_order: null;
  searchText: string;
  curentPage: 0;
  columnsAll = [
    { key: 'companyname', name: 'Unternehmensname' },
    { key: 'city', name: 'Stadt' },
    { key: 'position', name: 'Position' },
    { key: 'education', name: 'Ausbildung' },
    { key: 'continuing_education', name: 'Weiterbildungen' },
    { key: 'caretype', name: 'Pflegeart' },
    { key: 'shifthour', name: 'Schichten' },
    { key: 'scope', name: 'Arbeitsumfang' },
    { key: 'created', name: 'Erstellt' },
    { key: 'updated', name: 'Aktualisiert' },
    { key: 'action', name: 'Actionsarea' },
  ];
  columnsToDisplay = [
    'companyname',
    'city',
    'position',
    'education',
    'caretype',
    'shifthour',
    'scope',
    'created',
    'updated',
    'action',
  ];
  // this key is used to store to local storage,
  // if you are updating the columnsToDisplay you should change this as well
  readonly columnKey = 'jobTable';
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.pageV2(request, query),
    { property: '' as keyof Sortable, order: 'desc' },
    {
      companies: '',
      search: '',
      shift: '',
      care_type: '',
      agent_user: '',
      working_type: '',
      education_type: '',
      continuing_education: '',
      active: '',
      radius_search: '',
      latitude: '',
      longitude: '',
      position: '',
    },
    2
  );
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  singleSelectWithSearch: IDropdownSettings;
  dropdownCompanies = [];
  companiesList = [];
  selectedShiftHoursItems = [];
  selectedCareTypeItems = [];
  selectedCompanies = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  selectedContiEduItems = [];
  selectedAgent = [];
  selectedPositions = [];
  specialRequirementJobTypeItems = [];
  dropdownAgentList = [];
  companyAgent = [];

  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private translate: TranslateService,
    public constants: Constants
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.companyId = +params['id'];
        this.jobId = +params['jobId'];
        this.tabId = +params['tabId'];
        this.isCopy = +(params?.['isCopy'] ?? 0);
        this.postcode = +(params['postcode']?.length == 4)
          ? '0' + params['postcode']
          : params['postcode'];
        this.isNewCompany = false;
        this.isNewJob = false;
      })
    );
    this.searchSubcription = this.searchChanged$
      .pipe(debounceTime(500))
      .subscribe(input => this.dataSource.queryBy({ search: input }));
    const baseDropDownSettigs = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownSettings = {
      ...baseDropDownSettigs,
      singleSelection: false,
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      ...baseDropDownSettigs,
    };

    this.singleSelectWithSearch = {
      ...baseDropDownSettigs,
      allowSearchFilter: true,
    };

    this.getCompanyUser();

    this.getAgentList();
  }

  ngOnDestroy() {
    this.searchSubcription.unsubscribe();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getAgentList() {
    this.jobService.agents.subscribe((res: AgentUser[]) => {
      this.companyAgent = res;
      this.dropdownAgentList = (res || []).map(agent => ({
        id: agent.id,
        label: `${agent.first_name} ${agent.last_name}`,
      }));
    });
  }

  search() {
    this.searchChanged$.next(this.searchText);
  }

  searchFilterData(val) {
    const companyId = val.map(x => x.item_id);
    const searchVal = this.searchText ?? '';
    const shift = this.selectedShiftHoursItems.length
      ? this.selectedShiftHoursItems.map(x1 => x1.item_id).toString()
      : '';
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.pageV2(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        companies: companyId,
        search: searchVal,
        shift: shift,
        care_type: '',
        agent_user: '',
        working_type: '',
        education_type: '',
        continuing_education: '',
        active: '',
        radius_search: '',
        latitude: '',
        longitude: '',
      },
      2
    );
  }

  filterJob() {
    const searchVal = this.searchText ?? '';
    const companyId = this.selectedCompanies.length
      ? this.selectedCompanies.map(x1 => x1.item_id)
      : '';
    const shift = this.selectedShiftHoursItems.length
      ? this.selectedShiftHoursItems.map(x1 => x1.item_id)
      : '';
    const education = this.selectedEducationItems.length
      ? this.selectedEducationItems.map(x1 => x1.item_id)
      : '';
    const working_type = this.selectedScopeItems.length
      ? this.selectedScopeItems.map(x1 => x1.item_id)
      : '';

    const care_type = this.selectedCareTypeItems.length
      ? this.selectedCareTypeItems.map(x1 => x1.item_id)
      : '';
    const agent_user = this.selectedAgent.length
      ? this.selectedAgent.map(x1 => x1.item_id)
      : '';

    const continuing_education = this.selectedAgent.length
      ? this.selectedContiEduItems.map(x1 => x1.item_id)
      : '';

    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.pageV2(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        companies: companyId,
        search: searchVal,
        shift: shift,
        care_type: care_type,
        agent_user: agent_user,
        working_type: working_type,
        education_type: education,
        continuing_education: continuing_education,
        active: '',
        radius_search: '',
        latitude: '',
        longitude: '',
      },
      2
    );
  }

  handlePage(event) {
    this.curentPage = event.pageIndex === 0 ? 1 : event.pageIndex;
  }

  getCompanyUser() {
    this.jobService.getCompanies({ minimal: true }).subscribe((res: any) => {
      const companies = (res || []).map(company => ({
        id: company.id,
        label: company.name,
      }));

      this.dropdownCompanies = companies;
      this.companiesList = companies;
    });
  }

  getActiveJobs() {
    if (!this.isActiveJobs) return this.dataSource.queryBy({ active: '' });

    this.dataSource.queryBy({ active: 'true' });
  }

  getActiveProximity() {
    if (!this.isActiveproximity) {
      this.dataSource.queryBy({ radius_search: '' });
      this.dataSource.queryBy({ latitude: '', longitude: '' });
      this.isAddressActive = false;
      return;
    }
    this.dataSource.queryBy({ radius_search: 'true' });
    this.isAddressActive = true;
  }

  AddressChange(address: any) {
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    if (!this.isAddressActive)
      return this.dataSource.queryBy({ latitude: '', longitude: '' });

    this.dataSource.queryBy({ latitude: this.lat, longitude: this.lng });
  }

  ngAfterViewInit() {
    if (
      (this.tabId == 0 || this.tabId) &&
      this.jobId &&
      this.companyId
      // && this.isCopy == 0
    ) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    } else if (this.isCopy == 1) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    }
    this.cdr.detectChanges();
  }

  startClose() {
    this.location.go('/jobs');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getCompanyUser();
  }

  // add job create and edit stuff
  addNewJob(companyid) {
    this.selectedTab.select(0);
    this.companiesList = this.dropdownCompanies;
    this.drawer.open();
    this.isDublicate = false;
    this.isNewJob = true;
    this.isNewCompany = false;
  }

  // add company create and edit stuff
  addNewCompany() {
    this.drawer.open();
    this.selectedTab.select(1);
    this.isDublicate = false;
    this.isNewCompany = true;
    this.isNewJob = false;
  }

  //edit sidebar stuff
  edit(companyid, jobid, tabid, isCopy, postcode) {
    this.selectedTab.select(tabid);
    this.isDublicate = isCopy == 0 ? false : true;
    this.companyId = companyid;
    this.postcode = postcode;
    this.jobId = jobid;
    this.isNewJob = false;
    this.isNewCompany = false;
    this.location.go('/jobs/' + companyid + '/' + jobid + '/' + tabid);
    this.drawer.open();
  }

  checkIsJobUpdated($event) {
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  /* set job status active or inactive */
  updateJobStatus(jobId, status) {
    this.statusData = null;
    if (status === 'delete') {
      this.statusData = 3;
    } else if (status.checked) {
      this.statusData = 1;
    } else {
      this.statusData = 2;
    }

    if (this.statusData) {
      this.jobService
        .updateJobStatus({ is_active: this.statusData }, jobId)
        .subscribe(
          (res: any) => {
            this.dataSource.fetch(this.paginator.pageIndex);
          },
          error => {
            this._snackBar.open(error, 'OK', { duration: 5000 });
          }
        );
    }
  }

  queryBy(by: string, queryNgModel: Array<{ id: number; label: string }>) {
    let query = {};
    const ids = this.selectedEducationItems.map(x1 => x1.id);

    query[by] = queryNgModel.length ? queryNgModel.map(x1 => x1.id) : '';
    this.dataSource.queryBy(query);
  }

  delete(jobId) {
    const dialogRef = this.dialog.open(JobDeleteDialogComponent, {
      data: { messageType: 'delete-job' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.data == 'you confirmed') {
        this.jobService.getJobDelete(jobId).subscribe(
          (res: any) => {
            this.dataSource.fetch(this.paginator.pageIndex);
            this._snackBar.open(
              this.translate.instant('message.job_deleted'),
              'OK',
              {
                duration: 5000,
              }
            );
            this.updateJobStatus(jobId, 'delete');
          },
          errorRes => {
            this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          }
        );
      } else {
        console.log(' cencel button ');
      }
    });
  }
}

export interface companyResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}
