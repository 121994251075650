import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLoginComponent } from './components/authentication/admin-login/admin-login.component';

import { CompanyRegisterComponent } from './components/authentication/company-register/company-register.component';
import { HomeComponent } from './components/home/home.component';
import { JobHomeComponent } from './components/job-home/job-home.component';
import { AgentCompanyDashboardComponent } from './components/company/agent-company-dashboard/agent-dashboard.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MatchComponent } from './components/match/match.component';
import { MaslGuard } from './guards/masl.guard';
import { CompanyGuard } from './guards/company.guard';
import { PostingsComponent } from './components/postings/postings.component';
import { MobileUserComponent } from './components/common/mobile-user/mobile-user.component';
import DeactivateGuard from './guards/deactivated-guard';
import { MobileActiveGuard } from './guards/mobile-active.guard';
import { MobileViewGuard } from './guards/mobile-view.guard';
import { CompanyHomeComponent } from './components/company-home/company-home.component';

const routes: Routes = [
  {
    path: 'register',
    component: CompanyRegisterComponent,
    canActivate: [MobileActiveGuard],
  },
  {
    path: 'alogin',
    component: AdminLoginComponent,
    canActivate: [MobileActiveGuard],
  },
  {
    path: 'applicants',
    component: HomeComponent,
    canActivate: [MaslGuard],
    // useasdefault
    // useAsDefault: true,
    data: { role: 2 },
  },
  {
    path: 'applicants/:applierId/:tabId',
    component: HomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'jobs',
    component: JobHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'companies',
    component: CompanyHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'companies/:id',
    component: CompanyHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'jobs/:id',
    component: JobHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'jobs/:id/:jobId/:tabId',
    component: JobHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },

  {
    path: 'jobs/:id/:jobId/:tabId/:isCopy',
    component: JobHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'jobs/:id/:jobId/:tabId/:isCopy/:postcode',
    component: JobHomeComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },

  {
    path: 'forwardings',
    component: MatchComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'forwardings/:applierId/:companyId/:jobId/:id/:tabId',
    component: MatchComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'postings',
    component: PostingsComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'postings/:id/:jobId/:tabId/:isCopy/:postcode',
    component: PostingsComponent,
    canActivate: [MaslGuard],
    data: { role: 2 },
  },
  {
    path: 'dashboard',
    component: AgentCompanyDashboardComponent,
    canActivate: [CompanyGuard],
    data: { role: 3 },
  },
  {
    path: 'dashboard/:id/:jobId/:tabId/:isCopy',
    component: AgentCompanyDashboardComponent,
    canActivate: [CompanyGuard],
    data: { role: 3 },
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [MobileActiveGuard],
    // data: { role: 3 }
  },
  {
    path: 'mobile',
    component: MobileUserComponent,
    canDeactivate: [DeactivateGuard],
    canActivate: [MobileViewGuard],
  },
  {
    path: 'match',
    redirectTo: '/forwardings',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/applicants',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [DeactivateGuard],
})
export class AppRoutingModule {}
