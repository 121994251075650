import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JobService } from 'src/app/services/job.service';
import {
  CompanySidebarComponent,
  DialogData,
} from '../company-sidebar/companysidebar.component';
import { Constants } from 'src/app/models/abbreviation.model';
import { Contact } from 'src/app/type';

@Component({
  selector: 'app-add-company-user',
  templateUrl: './add-company-user.component.html',
  styleUrls: ['./add-company-user.component.scss'],
})
export class AddCompanyUserComponent implements OnInit {
  isNewUser = true;
  editMode: boolean;
  addCompanyForm: UntypedFormGroup;
  usertype: any;
  dropdownUserList: any;
  companyUsers: [];
  userForm: UntypedFormGroup;
  user_id: any;
  user: {
    id: number;
    email: string;
    gender: number;
    phone: string;
    first_name: string;
    last_name: string;
    name: string;
    company_role: string;
    contacts: Contact[];
  };
  dropdownUserSettings: IDropdownSettings;
  selectedUser: any;
  constructor(
    private jobService: JobService,
    private dialogRef: MatDialogRef<CompanySidebarComponent>,
    private fb: UntypedFormBuilder,
    public constants: Constants,
    private _snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.addCompanyForm = data.companyForm;
    this.dropdownUserSettings = data.dropdownUserSettings;
    this.editMode = data.editMode;
    this.selectedUser = data.selectedUser;
    this.user = data.user;
  }

  ngOnInit() {
    this.initForm();
    this.getUserList();
  }

  initForm() {
    this.userForm = this.fb.group({
      first_name: [this.user?.first_name ?? ''],
      last_name: [this.user?.last_name ?? '', Validators.required],
      email: [this.user?.email ?? '', Validators.required],
      gender: [this.user?.gender ?? '', Validators.required],
      sales: [false],
      usertype: ['new'],
      companyName: [''],
      company_role: [this.user?.company_role ?? ''],
      contacts: this.fb.array([this.defaultContact()]),
    });

    this.patchApplierContact(this.user?.contacts);

    if (this.addCompanyForm?.controls?.['companyName']?.value.length) {
      this.userForm.patchValue({
        companyName: this.addCompanyForm?.controls['companyName']?.value,
      });
      this.userForm.patchValue({ usertype: 'existing' });
      this.isNewUser = false;
    }

    this.userForm.get('usertype').valueChanges.subscribe(val => {
      this.getDefaultUser();
    });
    this.addCompanyForm?.patchValue({
      usertype: this.addCompanyForm.controls['usertype'].value,
    });
    this.userForm.get('usertype').value == 'existing'
      ? this.addCompanyForm?.controls['companyName']?.setValidators([
          Validators.required,
        ])
      : this.addCompanyForm?.controls['companyName']?.clearValidators();
  }

  get selectedContact() {
    return this.userForm.get('contacts') as UntypedFormArray;
  }

  addContact() {
    this.selectedContact.push(this.defaultContact());
  }

  deleteContact(index: number) {
    this.selectedContact.removeAt(index);

    if (this.selectedContact.length === 0) {
      this.addContact();
    }
  }

  patchApplierContact(user_contacts: Contact[]) {
    const contacts = user_contacts ?? [];
    if (!contacts.length) {
      this.resetContacts();
      return;
    }
    this.selectedContact.removeAt(0);
    contacts.map((contact, index, contacts) => {
      return this.selectedContact.push(
        this.fb.group({
          id: contact?.id,
          contact_type: contact.contact_type,
          contact: contact.contact,
        })
      );
    });
  }

  resetContacts() {
    this.selectedContact.clear();
    this.addContact();
  }

  defaultContact() {
    return this.fb.group({
      id: null,
      contact_type: ['PHONE', Validators.required],
      contact: [null, Validators.required],
    });
  }

  getUserList() {
    if (this.user) return;
    this.jobService.getUsers().subscribe((res: any) => {
      this.companyUsers = res;
      this.dropdownUserList = (this.companyUsers ?? []).map(user => {
        return {
          item_id: user['id'],
          item_text: `${user['first_name']} ${user['last_name']} (${user['email']})`,
        };
      });
    });
  }

  getDefaultUser() {
    if (this.userForm.get('usertype').value == 'new') {
      this.isNewUser = true;
      this.userForm.controls['email'].setValidators([Validators.required]);
      this.userForm.controls['last_name'].setValidators([Validators.required]);
      this.userForm.controls['gender'].setValidators([Validators.required]);
      this.userForm.controls['companyName'].clearValidators();
    } else {
      this.isNewUser = false;
      this.userForm.controls['email']?.clearValidators();
      this.userForm.controls['last_name']?.clearValidators();
      this.userForm.controls['gender']?.clearValidators();
      this.userForm.controls['companyName']?.setValidators([
        Validators.required,
      ]);
    }
    this.userForm.controls['email']?.updateValueAndValidity();
    this.userForm.controls['first_name']?.updateValueAndValidity();
    this.userForm.controls['last_name']?.updateValueAndValidity();
    this.userForm.controls['companyName']?.updateValueAndValidity();
  }

  findInvalidControls() {
    if (this.userForm.get('usertype').value == 'new') {
      const controls = this.userForm.controls;
      const cname = ['email', 'last_name', 'gender'];
      for (const name of cname) {
        if (controls[name].invalid) {
          return true;
        }
      }
    } else if (this.userForm.get('usertype').value == 'existing') {
      const controls = this.userForm.controls;
      const cname = ['companyName'];
      for (const name of cname) {
        if (controls[name].invalid) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  afterClosed($event): void {
    if (Boolean(this.user)) return this.updateUser($event);
    this.createUser($event);
  }

  createUser($event) {
    if (this.userForm.get('usertype').value == 'new') {
      const contacts = (this.userForm?.value?.contacts ?? []).filter(
        contact =>
          (!contact.id && contact.contact) || (contact.id && contact.contact)
      );
      const gender: string = this.userForm.value.gender;
      const userData = {
        email: this.userForm.value.email,
        first_name: this.userForm.value.first_name,
        last_name: this.userForm.value.last_name,
        gender: gender === 'null' ? null : gender,
        contacts: contacts,
        role: 3,
      };
      this.jobService.createUser(userData).subscribe(
        (response: any) => {
          this.dialogRef.close({
            user_id: +response?.id,
            usertype: 'new',
            selectedUser: this.userForm.controls['companyName'].value,
          });
        },
        error => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(error, 'OK', { duration: 5000 });
        }
      );
      return;
    }
    this.dialogRef.close({
      selectedUser: this.userForm.controls['companyName'].value,
      usertype: 'existing',
    });
  }

  updateUser($event) {
    const contacts = (this.userForm?.value?.contacts ?? []).filter(
      contact =>
        (!contact.id && contact.contact) || (contact.id && contact.contact)
    );

    const gender: string = this.userForm.value.gender;
    const userData = {
      email: this.userForm.value.email,
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      gender: gender === 'null' ? null : gender,
      contacts: contacts,
      role: 3,
      sales: false,
      company_role: this.userForm.value.company_role,
    };
    if (!this.user?.id) throw Error('user id required');
    this.jobService.updateUser(this.user.id, userData).subscribe(
      (response: any) => {
        this.dialogRef.close({
          user_id: +response?.id,
          user: userData,
        });
        this.user = null;
      },
      error => {
        $event.target.parentElement.disabled = false;
        $event.target.parentElement.classList.remove('mat-button-disabled');
        this._snackBar.open(error, 'OK', { duration: 5000 });
      }
    );
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
