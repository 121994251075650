import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { Sort } from '@angular/material/sort';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { PostingsService } from 'src/app/services/postings.service';
import { JobService } from 'src/app/services/job.service';
import { Constants } from '../../models/abbreviation.model';
import { AgentUser } from 'src/app/type';

moment.locale('de');

@Component({
  selector: 'app-postings',
  templateUrl: './postings.component.html',
  styleUrls: ['./postings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PostingsComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  companies = [];
  ShowFilter: boolean = false;
  companyId: number;
  jobId: number;
  tabId: number;
  isCopy: number;
  postcode: number;
  isJobUpdated: any;
  selectedDateRange: any;
  currentUrl: string;
  previousUrl: string;
  templates: any;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isNewJob = false;
  isDublicate: boolean = false;
  parentCompanyId: 0;
  // budgetsArr: Array<{ value: number; name: string }> = budget;
  // sidebar and tab stuff
  @ViewChild('drawer') drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // add the datasource
  sorting_order: null;
  search: null;
  curentPage: 0;
  columnsToDisplay = [
    'companyname',
    'PLZ',
    'created',
    'template',
    'caretype',
    'budget',
    'action',
  ];
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.posting(request, query),
    { property: '' as keyof Sortable, order: 'desc' },
    {
      pincode: '',
      company_ids: '',
      care_type: '',
      posting_templates: '',
      budget: '',
      start_date: '',
      end_date: '',
    },
    2
  );
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dropdownCompanies = [];
  companiesList = [];
  dropdownTemplateList = [];
  selectedShiftHoursItems = [];
  selectedCareTypeItems = [];
  selectedCompanies = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  selectedEducationAssistance = [];
  selectedTemplateItems = [];
  selectedBudgetItems = [];
  specificationCareItems = [];
  specialRequirementJobTypeItems = [];
  dropdownAgentList = [];
  selectedAgent = [];
  companyAgent = [];
  minDate: Moment;
  maxDate: Moment;
  locale: LocaleConfig = {
    applyLabel: 'OK', // detault is 'Apply'
    customRangeLabel: 'Zeitspanne',
    format: 'DD.MM.YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
  };
  ranges: any = {
    Heute: [moment(), moment()],
    Gestern: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private postingsService: PostingsService,
    public constants: Constants
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.companyId = +params['id'];
        this.jobId = +params['jobId'];
        this.tabId = +params['tabId'];
        this.isCopy = +params['isCopy'];
        this.postcode = +(params['postcode']?.length == 4)
          ? '0' + params['postcode']
          : params['postcode'];
        this.isNewCompany = false;
        this.isNewJob = false;
      })
    );
    const commonDropdownSettings = {
      idField: 'id',
      textField: 'label',
      searchPlaceholderText: 'Suche',
    };
    this.dropdownSettings = {
      ...commonDropdownSettings,
      singleSelection: false,
      enableCheckAll: false,
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      ...commonDropdownSettings,
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };
    this.getCompanyUser();

    this.getAgentList();
    this.getTemplate();
  }

  getAgentList() {
    this.dropdownAgentList = [];
    this.jobService.agents.subscribe((res: AgentUser[]) => {
      this.companyAgent = res;
      for (let agent of this.companyAgent) {
        this.dropdownAgentList.push({
          id: agent.id,
          label: agent.first_name + ' ' + agent.last_name,
        });
      }
      this.dropdownAgentList = new Array(this.dropdownAgentList);
    });
  }

  searchFilterData(val) {
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.posting(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        pincode: '',
        company_ids: '',
        care_type: '',
        posting_templates: '',
        budget: '',
        start_date: '',
        end_date: '',
      },
      2
    );
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }

  getCompanyUser() {
    this.jobService.getCompanies({ minimal: true }).subscribe((res: any) => {
      let Companies = [];
      res.forEach((company: any) =>
        Companies.push({ id: company.id, label: company.name })
      );
      this.dropdownCompanies = Companies;
      this.companiesList = Companies;
    });
  }

  ngAfterViewInit() {
    if (
      (this.tabId == 0 || this.tabId) &&
      this.jobId &&
      this.companyId &&
      this.isCopy == 0
    ) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    } else if (this.isCopy == 1) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    }
    this.cdr.detectChanges();
  }

  startClose() {
    this.location.go('/postings');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getCompanyUser();
  }

  // add job create and edit stuff
  addNewJob(companyid) {
    this.selectedTab.select(0);
    this.companiesList = this.dropdownCompanies;
    this.drawer.open();
    this.isDublicate = false;
    this.isNewJob = true;
    this.isNewCompany = false;
  }

  // add company create and edit stuff
  addNewCompany() {
    this.drawer.open();
    this.selectedTab.select(1);
    this.isDublicate = false;
    this.isNewCompany = true;
    this.isNewJob = false;
  }

  //edit sidebar stuff
  edit(companyid, jobid, tabid, isCopy, postcode) {
    this.selectedTab.select(tabid);
    this.isDublicate = isCopy == 0 ? false : true;
    this.companyId = companyid;
    this.postcode = postcode;
    this.jobId = jobid;
    this.isNewJob = false;
    this.isNewCompany = false;
    this.location.go(
      '/postings/' +
        companyid +
        '/' +
        jobid +
        '/' +
        tabid +
        '/' +
        isCopy +
        '/' +
        postcode
    );
    this.drawer.open();
  }

  checkIsJobUpdated($event) {
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  /* set job status active or inactive */
  updateJobStatus(jobId, status) {
    let statusData = { is_active: status.checked };
    this.jobService.updateJobStatus(statusData, jobId).subscribe(
      (res: any) => {
        this.dataSource.fetch(this.paginator.pageIndex);
      },
      error => {
        this._snackBar.open(error, 'OK', { duration: 5000 });
      }
    );
  }

  queryBy(by, queryNgModel) {
    var query = {};
    if (by != 'date_range') {
      query[by] = queryNgModel.length > 0 ? queryNgModel.map(x1 => x1.id) : '';
    }

    this.dataSource.queryBy(query);

    const searchVal = this.search !== undefined ? this.search : '';
    const company_ids =
      this.selectedCompanies.length > 0
        ? this.selectedCompanies.map(x1 => x1.id)
        : '';
    const posting_templates =
      this.selectedTemplateItems.length > 0
        ? this.selectedTemplateItems.map(x1 => x1.id)
        : '';
    var startdt = new Date(this.selectedDateRange?.startDate?._d);
    var enddt = new Date(this.selectedDateRange?.endDate?._d);

    const care_type =
      this.selectedCareTypeItems.length > 0
        ? this.selectedCareTypeItems.map(x1 => x1.id)
        : '';

    const budget =
      this.selectedBudgetItems.length > 0
        ? this.selectedBudgetItems.map(x1 => x1.id)
        : '';

    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.posting(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        pincode: searchVal,
        company_ids: company_ids,
        care_type: care_type,
        posting_templates: posting_templates,
        budget: budget,
        start_date:
          this.selectedDateRange?.startDate?._d === undefined
            ? ''
            : startdt.getFullYear() +
              '-' +
              ((startdt || new Date()).getMonth() + 1) +
              '-' +
              startdt.getDate(),
        end_date:
          this.selectedDateRange?.endDate?._d === undefined
            ? ''
            : enddt.getFullYear() +
              '-' +
              ((enddt || new Date()).getMonth() + 1) +
              '-' +
              enddt.getDate(),
      },
      2
    );
  }

  getTemplate() {
    const careType = [];
    this.postingsService.getTemplateData(careType).subscribe(
      (response: any) => {
        let tempArr = [];
        for (let tem of response) {
          let tempObj = {
            id: Number(tem.id),
            label: tem.name,
          };
          tempArr.push(tempObj);
        }
        this.dropdownTemplateList = tempArr;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }
}

export interface companyResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}

export interface Sortable {
  posting_date: Date;
  name: any;
  location: any;
}
