<mat-dialog-content>
  {{ 'match.confirm_dialog.description' | translate }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="confirm(false)">
    {{ 'match.confirm_dialog.cancel' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="confirm(true)">
    {{ 'match.confirm_dialog.confirm' | translate }}
  </button>
</mat-dialog-actions>
