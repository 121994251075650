import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

export type ConstantKeys =
  | 'role_type'
  | 'social_account_type'
  | 'shift_hour_type'
  | 'shift_time_type'
  | 'working_hours_type'
  | 'care_scope'
  | 'care_type'
  | 'education_type'
  | 'reference_type'
  | 'match_stage'
  | 'specification_job_care_type'
  | 'special_requirement_job_type'
  | 'gender_type'
  | 'channel_type'
  | 'trigger_type'
  | 'continuing_education_type'
  | 'vaccination_status_type'
  | 'job_driving_license_type'
  | 'driver_license_type'
  | 'budget'
  | 'position'
  | 'area_of_experience'
  | 'work_experience'
  | 'language_skill'
  | 'salutation'
  | 'ownership'
  | 'ats_software'
  | 'company_size'
  | 'applicant_source'
  | 'company_match_status'
  | 'applier_status'
  | 'applier_flag'
  | 'match_flag'
  | 'actor'
  | 'job_status'
  | 'message_channel'
  | 'contact_type'
  | 'user_file_type';

export type ConstantsType = {
  [Key in ConstantKeys]: ConstItem[];
};

export interface ConstItem {
  id: string | number;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  constants = new BehaviorSubject<ConstantsType | undefined>(undefined);

  constructor(private http: HttpClient) {}

  fetchConstants() {
    const URL = 'common/constants/';
    this.http
      .get(`${environment.BASE_URL}${environment.PATH}${URL}`)
      .pipe(take(1))
      .subscribe(
        (data: ConstantsType) => {
          this.constants.next(data);
        },
        error => {
          console.error(error);
        }
      );
  }
}
