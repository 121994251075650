import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaslGuard implements CanActivate {
  isMobile = false;
  constructor(private deviceService: DeviceDetectorService, private msalService: MsalService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let response = JSON.parse(localStorage.getItem("agentData"));
    this.isMobile = this.deviceService.isMobile();
    
    if (this.isMobile === true) {
      this.router.navigate(['mobile']);
      return false
    }else
    
    if (!response) {
      this.router.navigate(['alogin'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }

}
