import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { Observable } from 'rxjs';

export interface customQuery {
  search: string;
  need_action: string;
  radius_search: string;
  latitude: string;
  longitude: string;
  education_type?: string;
  status?: string;
  care_type?: string;
  reference_by?: string;
  start_date?: Date;
  end_date?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicantService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  get(url, params = {}, isPaginate = false) {
    if (isPaginate) {
      return this.http.get(url, params).pipe(catchError(this.handleError));
    }

    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  post(url, data, httpOptions = {}) {
    return this.http
      .post(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  patch(url: string, data: any, httpOptions = {}) {
    return this.http
      .patch(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  put(url, data, httpOptions = {}) {
    return this.http
      .put(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(url: string, httpOptions = {}) {
    return this.http
      .delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPdf(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  getApplier() {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier/`, httpOptions);
  }

  getApplierFlags(applierId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier-flags/${applierId}/`, httpOptions);
  }

  createApplierFlags(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/applier-flags/`, data, httpOptions);
  }

  deletedApplierFlags(applierId, data) {
    const httpOptions = this.authService.getHTTPOption();
    httpOptions['body'] = data;
    return this.http.delete(
      `${environment.BASE_URL}${environment.PATH}accounts/applier-flags/${applierId}/`,
      httpOptions
    );
  }

  getMatchList(applierId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`match/appliermatchlist/${applierId}/`, httpOptions);
  }

  applyFilter(filterValue = '', ordering = '') {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/applier/?search=${filterValue}&ordering=${ordering}/`,
      httpOptions
    );
  }

  updateApplierDetails(id, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/applier/${id}/`, data, httpOptions);
  }

  uploadUserFile(userExternalId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(
      `accounts/user/${userExternalId}/files/`,
      data,
      httpOptions
    );
  }

  deleteUserFile(userExternalId: string, fileId: number) {
    if (!userExternalId || !fileId) throwError('user id and file id required');
    const httpOptions = this.authService.getHTTPOption();
    return this.delete(
      `accounts/user/${userExternalId}/files/${fileId}/`,
      httpOptions
    );
  }

  restoreUserFiles(userExternalId: string, fileId: number, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(
      `accounts/user/${userExternalId}/files/${fileId}`,
      data,
      httpOptions
    );
  }

  sendMatches(matchId, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`match/matchstatuslist/`, data, httpOptions);
  }

  sendJobOffer(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`match/send-job-offer/`, data, httpOptions);
  }

  updateNeedAction(data, agentId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(
      `accounts/change-need-action/${agentId}/`,
      data,
      httpOptions
    );
  }

  patchApplier(id, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.patch(`accounts/applier/${id}/`, data, httpOptions);
  }

  downloadPdf(applierId) {
    const token = this.authService.getAuthToken();
    const httpOptions = new HttpHeaders({
      Authorization: token,
    });
    return this.getPdf(`match/download-pdf/${applierId}/download/`, {
      responseType: 'blob' as 'json',
      headers: httpOptions,
    });
  }

  public handleError(errorRes: HttpErrorResponse) {
    if (Object.keys(errorRes?.error)?.length > 0) {
      if (
        Object.keys(errorRes?.error)[0]?.length > 1 &&
        errorRes?.statusText !== 'Unknown Error'
      ) {
        return throwError(
          Object.keys(errorRes.error)[0] +
            ' ' +
            Object.values(errorRes.error)[0]
        );
      }
    }
    let message = errorRes?.statusText
      ? errorRes?.statusText
      : 'etwas ist schief gelaufen!';
    return throwError(message);
  }

  page(request: PageRequest<any>, query: customQuery): Observable<Page<any>> {
    const httpOptions = this.authService.getHTTPOption();
    let ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering !== '') {
        ordering = '-' + ordering;
      }
    } else {
      ordering = '';
    }

    const params = {
      params: {
        ...query,
        page: request.page + 1,
        ordering: ordering,
        radius_search: (query?.radius_search ?? '').toString(),
        reference_by: query?.reference_by || '',
        latitude: (query?.latitude ?? '').toString(),
        longitude: (query?.longitude ?? '').toString(),
        start_date: (query?.start_date?.toISOString() ?? '').toString(),
        end_date: (query?.end_date?.toISOString() ?? '').toString(),
      },
      ...httpOptions,
    };
    const url = 'accounts/applier/';
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map(res => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }
}
