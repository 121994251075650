import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { JobService } from 'src/app/services/job.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { AddCompanyUserComponent } from '../add-company-user/add-company-user.component';

export interface DialogData {
  editMode: boolean;
  isSpinner: boolean;
  companyForm: FormGroup;
  usertype: any;
  selectedUser: [];
  dropdownUserSettings: IDropdownSettings;
  companyUsers: [];
  user_id: any;
  user?: any;
  match_ids?: number[];
}

export interface PeriodicElement {
  name: string;
  contactuser: boolean;
  email: string;
  phone: string;
}

export interface Agent {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  gender: number | null;
  role: number;
}

@Component({
  selector: 'app-companysidebar',
  templateUrl: './companysidebar.component.html',
  styleUrls: ['./companysidebar.component.scss'],
})
export class CompanySidebarComponent implements OnInit, OnChanges {
  @Input('companyId') companyId: number;
  @Input('isNewCompany') isNewCompany: any;
  @Input('showUser') showUser: boolean = true;
  @Input() editMode: boolean = true;
  @Input('companyPresentation') companyPresentation: any;
  @Output() updateAndClose = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  companyForm: FormGroup;
  companyDetails: any;
  usertype: any;
  userRole: number;
  companyUser: any;
  user_id: any;
  isSpinner: boolean = false;
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  dropdownUserSettings: IDropdownSettings;
  dropdownSingleSettings: IDropdownSettings;
  dropdownAgentList = [];
  selectedUser = [];
  selectedAgent = [];
  companyUsers = [];
  dataSource: any;
  columnsToDisplay = ['name', 'contactuser', 'action'];

  constructor(
    private jobService: JobService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cdref: ChangeDetectorRef
  ) {
    let userData = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    this.userRole = userData.role;
  }

  ngOnInit() {
    this.initForm();
    if (this.companyId) {
      if (this.isNewCompany == 'true') {
        this.initNewForm();
      } else {
        this.getCompanyDetails();
      }
    }
    this.getCompanyUser();
  }

  ngOnChanges() {
    if (this.companyId) {
      if (this.isNewCompany == 'true') {
        this.initNewForm();
      } else {
        this.getCompanyDetails();
      }
    }
    this.getCompanyUser();
  }

  initForm() {
    this.companyForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      agent_user: new FormControl(''),
      website: new FormControl('', Validators.pattern(this.reg)),
      usertype: new FormControl('new'),
      external_name: new FormControl(''),
      phone_number: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      gender: new FormControl(''),
      about: new FormControl(''),
      sales: new FormControl(false),
    });

    if (this.userRole == 2) {
      this.companyForm.controls['agent_user'].setValidators([
        Validators.required,
      ]);
      this.companyForm.controls['agent_user'].updateValueAndValidity();
    }

    this.dropdownUserSettings = {
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.dropdownSingleSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.getAgentList();
  }

  getAgentList() {
    this.dropdownAgentList = [];
    this.jobService.getAgent(false).subscribe((res: Agent[]) => {
      this.dropdownAgentList = (res || []).map(agent => ({
        item_id: agent.id,
        item_text: `${agent?.first_name} ${agent?.last_name}`,
        gender: agent.gender,
      }));
    });
  }

  getCompanyUser() {
    if (!isNaN(this.companyId)) {
      this.jobService
        .getCompanyUser({ company_id: this.companyId })
        .subscribe((res: any) => {
          this.dataSource = res;
        });
    }
  }

  getCompanyDetails() {
    let sesstionResult = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    let userArr = [];
    if (!isNaN(this.companyId)) {
      this.isSpinner = true;
      this.companyForm?.get('companyName').clearValidators();
      this.companyForm?.get('companyName').updateValueAndValidity();
      this.jobService
        .getCompanyDetails(this.companyId)
        .subscribe((res: any) => {
          this.companyDetails = res;
          if (this.companyDetails?.user) {
            for (let usr of this.companyDetails?.user) {
              if (sesstionResult.id !== usr?.id) {
                this.user_id =
                  this.user_id != undefined ? this.user_id + ',' : '' + usr.id;
                let userObj = {
                  item_id: usr.id,
                  item_text: usr?.first_name + ' ' + usr?.last_name,
                };
                userArr.push(userObj);
              }
            }
            this.selectedUser = userArr;
            this.companyForm.patchValue({ companyName: userArr });
          }

          this.selectedAgent = [];
          const agent_user = this.companyDetails?.agent_user;
          if (agent_user) {
            this.selectedAgent.push({
              item_id: agent_user?.id,
              item_text: `${agent_user?.first_name} ${agent_user?.last_name}`,
              gender: agent_user.gender,
            });
          }
          this.companyForm.patchValue({
            id: this.companyDetails.id,
            name: this.companyDetails.name,
            website: this.companyDetails.website,
            external_name: this.companyDetails.external_name,
            phone_number: this.companyDetails.phone_number,
            about: this.companyDetails.about,
            gender: this.companyDetails.gender,
            sales: this.companyDetails.sales,
            usertype: 'existing',
          });
          this.isSpinner = false;
        });
    }
  }

  initNewForm() {
    if (!this.companyForm) {
      this.initForm();
    } else {
      this.companyForm.patchValue({
        id: '',
        name: '',
        website: '',
        usertype: 'new',
        companyName: [],
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        agent_user: [],
        gender: '',
        about: '',
        external_name: '',
        sales: false,
      });
    }
  }

  updateContactUser(user_id, $event) {
    let data = {
      company_id: +this.companyId,
      user_id: user_id,
      contact_user: $event.checked,
    };
    this.jobService.updateContactUserStatus(data).subscribe(res => {
      this.getCompanyUser();
    });
  }

  doCreateCompany(isClose = false, $event: any) {
    $event.target.parentElement.disabled = true;
    $event.target.parentElement.classList.add('mat-button-disabled');

    if (this.companyForm.valid) {
      // 1. new user: 2 APIs will be called
      //   |_ create new user first ; https://127.0.0.1:8888/api/v1/accounts/user/
      //   |_ then create company ; https://127.0.0.1:8888/api/v1/accounts/company/
      // 2. existing user: 1 API will be called
      //   |_ create company ; https://127.0.0.1:8888/api/v1/accounts/company/
      let sesstionResult = JSON.parse(localStorage.getItem('agentData'))
        ? JSON.parse(localStorage.getItem('agentData'))
        : JSON.parse(localStorage.getItem('companyData'));
      let companyData;
      const companyArray = [];
      if (this.companyForm.value.usertype == 'new') {
        if (this.userRole == 2) {
          companyArray.push({
            user: [this.user_id],
            company_name: this.companyForm.value.name,
            website: this.companyForm.value.website,
            phone_number: this.companyForm.value.phone_number,
            about: this.companyForm.value.about,
            external_name: this.companyForm.value.external_name,
            company_id: Number(sesstionResult.company_id),
            agent_user: this.companyForm.value?.agent_user[0]['item_id'],
            sales: this.companyForm.value?.sales,
          });
        } else {
          companyArray.push({
            user: [this.user_id],
            company_name: this.companyForm.value.name,
            website: this.companyForm.value.website,
            phone_number: this.companyForm.value.phone_number,
            about: this.companyForm.value.about,
            external_name: this.companyForm.value.external_name,
            company_id: Number(sesstionResult.company_id),
            sales: false,
          });
        }
        this.jobService.addCompanyDetails(companyArray[0]).subscribe(
          (response: any) => {
            if (isClose) {
              this.updateAndClose.emit(response);
            } else {
              this.update.emit(response);
            }
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');

            this.initNewForm();
            this._snackBar.open(
              this.translate.instant('message.company_details_update'),
              'OK',
              { duration: 5000 }
            );
          },
          error => {
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
            this._snackBar.open(error, 'OK', { duration: 5000 });
          }
        );
      } else {
        let userList = [];
        for (let user of this?.selectedUser) {
          userList.push(user.item_id);
        }
        if (this.userRole == 2) {
          companyArray.push({
            user: userList,
            company_name: this.companyForm.value.name,
            website: this.companyForm.value.website,
            phone_number: this.companyForm.value.phone_number,
            about: this.companyForm.value.about,
            external_name: this.companyForm.value.external_name,
            agent_user: this.companyForm.value?.agent_user[0]['item_id'],
            sales: this.companyForm.value?.sales,
          });
        } else {
          companyArray.push({
            user: userList,
            company_name: this.companyForm.value.name,
            website: this.companyForm.value.website,
            phone_number: this.companyForm.value.phone_number,
            about: this.companyForm.value.about,
            external_name: this.companyForm.value.external_name,
            sales: false,
          });
        }
        this.jobService.addCompanyDetails(companyArray[0]).subscribe(
          (response: any) => {
            const snackBarRef = this._snackBar.open(
              this.translate.instant('message.company_added'),
              'OK',
              { duration: 5000 }
            );
            if (isClose) {
              this.updateAndClose.emit(response);
            } else {
              this.update.emit(response);
            }
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
            this.initNewForm();
          },
          error => {
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
            this._snackBar.open(error, 'OK', { duration: 5000 });
          }
        );
      }
    }
  }

  doUpdateCompany(isClose = true, $event) {
    $event.target.parentElement.disabled = true;
    $event.target.parentElement.classList.add('mat-button-disabled');

    let loginUser = JSON.parse(localStorage.getItem('agentData'));
    const cid = !isNaN(this.companyId) ? this.companyId : loginUser.id;
    let userList = [];
    for (let user of this?.selectedUser) {
      userList.push(user.item_id);
    }
    if (this.companyForm.value.usertype == 'new') {
      const sesstionResult = JSON.parse(localStorage.getItem('agentData'));
      if (this.user_id) {
        userList.push(this.user_id);
      }
      const companyUpdateArray = [];
      if (this.userRole == 2) {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],
          user: userList,
          sales: this.companyForm.value?.sales,
        });
      } else {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          user: userList,
          sales: false,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.getCompanyUser();

            if (isClose) {
              this.updateAndClose.emit(response);
            } else {
              this.update.emit(response);
            }
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
            this._snackBar.open(
              this.translate.instant('message.company_details_update'),
              this.translate.instant('message.btn_ok')
            );
          },
          errorRes => {
            this._snackBar.open(
              errorRes,
              this.translate.instant('message.btn_ok'),
              { duration: 5000 }
            );
          }
        );
    } else {
      const companyUpdateArray = [];
      if (this.userRole == 2) {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],
          user: userList,
          sales: this.companyForm.value?.sales,
        });
      } else {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          user: userList,
          sales: false,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.getCompanyUser();
            if (isClose) {
              this.updateAndClose.emit(response);
            } else {
              this.update.emit(response);
            }
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
          },
          errorRes => {
            $event.target.parentElement.disabled = false;
            $event.target.parentElement.classList.remove('mat-button-disabled');
            this._snackBar.open(
              errorRes,
              this.translate.instant('message.btn_ok'),
              { duration: 5000 }
            );
          }
        );
    }
  }

  addCompanyDialog() {
    this.cdref.detectChanges();
    const dialogRef = this.dialog.open(AddCompanyUserComponent, {
      data: {
        editMode: this.editMode,
        companyForm: this.companyForm,
        usertype: this.usertype,
        selectedUser: this.selectedUser,
        dropdownUserSettings: this.dropdownUserSettings,
        isSpinner: this.isSpinner,
        companyUsers: this.companyUsers,
        user_id: this.user_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedUser = result?.selectedUser ? result?.selectedUser : [];
        this.user_id = result?.user_id ? result?.user_id : '';
        this.companyForm.patchValue({
          usertype: result?.usertype,
          companyName: result?.selectedUser ? result?.selectedUser : '',
        });
        result?.usertype == 'existing'
          ? this.companyForm.controls['companyName'].setValidators([
              Validators.required,
            ])
          : this.companyForm.controls['companyName'].clearValidators();
        this.companyForm.controls['companyName'].updateValueAndValidity();
      }
    });
  }

  resetData() {
    this.close.emit();
  }
}
