export interface HistoryMatchInterface {
  content: string;
  match_id: number;
  status_id: number;
  channel: number;
  // due_days: Date;
  due_date: string;
}
export class HistoryMatch implements HistoryMatchInterface {
  constructor(
      public content: string,
      public match_id: number,
      public status_id: number,
      public channel: number,
      public due_date: string,
      public costum_Date: string
  ) { }
}
