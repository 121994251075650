import { CanDeactivate, Router } from '@angular/router';
import {Injectable} from '@angular/core';
import { MobileUserComponent } from '../components/common/mobile-user/mobile-user.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export default class DeactivateGuard implements CanDeactivate<MobileUserComponent> {
    isMobile = false;
  constructor(private deviceService: DeviceDetectorService, private router: Router) {}
  canDeactivate(component: MobileUserComponent) {
    this.isMobile = this.deviceService.isMobile();    
      if(this.isMobile === true){
        this.router.navigate(['mobile']);
        return false
      }
    return true;
  }

}