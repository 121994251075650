import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgentCompanyDashboardComponent } from '../../company/agent-company-dashboard/agent-dashboard.component';

@Component({
  selector: 'app-agent-details-dialog',
  templateUrl: './agent-details-dialog.component.html',
  styleUrls: ['./agent-details-dialog.component.scss']
})
export class AgentDetailsDialogComponent implements OnInit {

  messageType: any;
  agentDetails: any;
  copyToClipboard: string;
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<AgentCompanyDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.agentDetails = data.companyDetails;
    
  }

  ngOnInit(){
    
  }

  doRefresh() {}

  cancel() {
    // closing itself and sending data to parent component
    // this.dialogRef.close({ data: 'you cancelled' });
  }

  CopyDetails(string) {
    this.copyToClipboard = '';
    this.copyToClipboard =  string;
  }

  confirm() {
    // closing itself and sending data to parent component
    // this.dialogRef.close({ data: 'you confirmed' });
  }

}
