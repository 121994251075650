import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import historyMatchChannelData from './abbreviations/historyMatchChannel.json';
import {
  ConstItem,
  ConstantKeys,
  ConstantsService,
} from '../services/constants.service';

export interface abbreviation {
  id: number;
  icon?: string;
  name?: string;
  fields: AbbreviationFields;
}

export interface AbbreviationFields {
  short_name: string;
  full_name: string;
}

export interface SelectList {
  item_id: number;
  item_text: string;
}

export type AbbreviationTypes =
  | 'shiftHours'
  | 'careType'
  | 'matchStatus'
  | 'specification'
  | 'specialJobType'
  | 'educationType'
  | 'additionalQue.'
  | 'reference'
  | 'eduAssistance'
  | 'continuingEdu.'
  | 'status'
  | 'vaccinationType'
  | 'licenseType'
  | 'scope'
  | 'historyMatch';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  constructor(private constantsService: ConstantsService) {}
  public getList(type: ConstantKeys): Observable<ConstItem[] | []> {
    return this.constantsService.constants.pipe(
      take(1),
      map(constants => {
        const types = {
          role_type: constants.role_type,
          social_account_type: constants.social_account_type,
          shift_hour_type: constants.shift_hour_type,
          shift_time_type: constants.shift_time_type,
          working_hours_type: constants.working_hours_type,
          care_scope: constants.care_scope,
          care_type: constants.care_type,
          education_type: constants.education_type,
          reference_type: constants.reference_type,
          match_stage: constants.match_stage,
          specification_job_care_type: constants.specification_job_care_type,
          special_requirement_job_type: constants.special_requirement_job_type,
          gender_type: constants.gender_type,
          channel_type: constants.channel_type,
          trigger_type: constants.trigger_type,
          continuing_education_type: constants.continuing_education_type,
          vaccination_status_type: constants.vaccination_status_type,
          job_driving_license_type: constants.job_driving_license_type,
          driver_license_type: constants.driver_license_type,
          budget: constants.budget,
          position: constants.position,
          area_of_experience: constants.area_of_experience,
          work_experience: constants.work_experience,
          language_skill: constants.language_skill,
          salutation: constants.salutation,
          ownership: constants.ownership,
          ats_software: constants.ats_software,
          company_size: constants.company_size,
          applicant_source: constants.applicant_source,
          company_match_status: constants.company_match_status,
          applier_status: constants.applier_status,
          applier_flag: constants.applier_flag,
          match_flag: constants.match_flag,
          actor: constants.actor,
          job_status: constants.job_status,
          message_channel: constants.message_channel,
          user_file_type: constants.user_file_type,
          contact_type: constants.contact_type.filter(
            value => !['SMS', 'EMAIL'].includes(value.id as string)
          ),
        };
        return types[type] ?? [];
      })
    );
  }

  getMatchStage(id): ConstItem {
    let stage;
    this.getList('match_stage')
      .pipe(take(1))
      .subscribe(stages => {
        stage = stages.find((s: ConstItem) => s?.id === id);
      });
    return stage;
  }

  getJobStage(id): ConstItem {
    let stage;
    this.getList('job_status')
      .pipe(take(1))
      .subscribe(stages => {
        stage = stages.find((s: ConstItem) => s?.id === id);
      });
    return stage;
  }

  getMatchFlag(id: string): ConstItem {
    let flag;
    this.getList('match_flag')
      .pipe(take(1))
      .subscribe(flags => {
        flag = flags.find((f: ConstItem) => f?.id === id);
      });
    return flag;
  }

  getApplierFlags(id: string): ConstItem {
    let flag;
    this.getList('applier_flag')
      .pipe(take(1))
      .subscribe(flags => {
        flag = flags.find((f: ConstItem) => f?.id === id);
      });
    return flag;
  }
}

export class AbbreviationModel {
  public getList(type: AbbreviationTypes): abbreviation[] {
    let data: abbreviation[] = [];
    if (type == 'historyMatch') {
      data = historyMatchChannelData;
    }

    return data;
  }

  public getById(id: any, type: AbbreviationTypes, field = 'short'): string {
    let data: abbreviation[] = this.getList(type);
    const el = data.find(val => val.id == id);
    if (!el) return '';
    if (field == 'short') return el.fields.short_name;
    if (field == 'icon') return el.icon;
    return el.fields.full_name;
  }

  /**
   * get history matche channel
   */
  protected historyMatchChannel = [
    {
      id: 1,
      icon: 'whatsapp',
      name: 'WhatsApp',
    },
    {
      id: 2,
      icon: 'phone',
      name: 'Telefon',
    },
    {
      id: 3,
      icon: 'mail',
      name: 'E-mail',
    },
  ];

  /**
   * get history matche channel
   */

  getHistoryMatchChannelById(history) {
    for (let hist of this.historyMatchChannel) {
      if (hist.id == history) {
        return hist.icon;
      }
    }
  }

  getHistoryMatchChannel() {
    return this.historyMatchChannel;
  }
}
