import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { InterviewSlot } from 'src/app/type';
import { MatchInterviewComponent } from '../../match-interview.component';
import moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-match-interview-dialog',
  templateUrl: './match-interview-dialog.component.html',
  styleUrls: ['./match-interview-dialog.component.scss'],
})
export class MatchInterviewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { interview: InterviewSlot; match: any },
    private historyMatchService: HistoryMatchService,
    private dialogRef: MatDialogRef<MatchInterviewComponent>
  ) {
    if (this.data?.interview?.schedule_time) {
      this.interview.setValue({
        selectedDate: moment(this.data?.interview?.schedule_time),
        selectedTime: moment(this.data?.interview?.schedule_time).format(
          'HH:mm'
        ),
      });
    }
  }
  interview = new UntypedFormGroup({
    selectedDate: new UntypedFormControl(null, [Validators.required]),
    selectedTime: new UntypedFormControl(null, [Validators.required]),
  });

  createInterview() {
    const [hour, minute] = this.interview.value?.selectedTime?.split(':') ?? [];
    this.interview.value.selectedDate.set({
      h: hour ?? 0,
      m: minute ?? 0,
    });
    const payload = {
      schedule_time: this.interview.value?.selectedDate?.utc(),
    };
    this.historyMatchService
      .createInterviewSlot(this.data?.match?.extID, payload)
      .pipe(take(1))
      .subscribe({
        next: (response: InterviewSlot) => {
          this.interview.reset();

          this.dialogRef.close({ ...response });
        },
      });
  }

  updateInterview() {
    const [hour, minute] = this.interview.value?.selectedTime?.split(':') ?? [];
    this.interview.value.selectedDate.set({
      h: hour ?? 0,
      m: minute ?? 0,
    });
    this.historyMatchService
      .updateInterviewSlots(this.data?.interview?.external_id, {
        schedule_time: this.interview.value?.selectedDate?.utc(),
      })
      .pipe(take(1))
      .subscribe({
        next: (response: InterviewSlot) => {
          this.interview.reset();

          this.dialogRef.close({ ...response });
        },
      });
  }

  onSubmit() {
    if (this.data?.interview?.external_id) {
      this.updateInterview();
      return;
    }
    if (this?.data?.match) {
      this.createInterview();
    }
  }
}
