import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/services/applicant.service';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Constants, SelectList } from '../../models/abbreviation.model';
import { HistoryService } from 'src/app/services/history.service';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { environment } from 'src/environments/environment';
import { debounceTime, take } from 'rxjs/operators';
moment.locale('de');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  // encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild('matchDrawer') matchDrawer: MatSidenav;
  @Input('matSortDirection') direction: SortDirection;
  @Input() hasBackdrop: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscriptions: Array<Subscription> = [];

  dataSource = new PaginationDataSource(
    (request, query) => this.applicantService.page(request, query),
    { property: '' as keyof Sortable, order: 'desc' },
    {
      search: '',
      need_action: '',
      radius_search: '',
      latitude: '',
      longitude: '',
      status: '',
      education_type: '',
      care_type: '',
      start_date: null,
      end_date: null,
      reference_by: null,
    },
    2
  );

  columnsAll = [
    { key: 'name', name: 'Name' },
    { key: 'date', name: 'Datum' },
    { key: 'education', name: 'Ausbildung' },
    { key: 'continous_education', name: 'Weiterbildungen' },
    { key: 'care_type', name: 'Pflegeart' },
    { key: 'shift_hour', name: 'Schichten' },
    { key: 'scope', name: 'Arbeitsumfang' },
    { key: 'location', name: 'Standort' },
    { key: 'status', name: 'Satus' },
    { key: 'action', name: 'Actionsarea' },
  ];
  columnsToDisplay = [
    'name',
    'date',
    'education',
    'continous_education',
    'care_type',
    'shift_hour',
    'scope',
    'location',
    'status',
    'action',
  ];
  // this key is used to store to local storage,
  // if you are updating the columnsToDisplay you should change this as well
  readonly columnKey = 'applierTable_V1';
  opened: boolean;
  applierData: string;
  needActionChecked = false;
  applicantForm: FormGroup;
  selectedApplierId: any;
  selectedTab = new SelectionModel(false, [0]);
  selectedMatchDrawerTab = new SelectionModel(false, [2]);
  expandedElement = null;
  sorting_order: null;
  currentPage: any;
  isMobile = false;
  isActiveproximity = false;
  isAddressActive = false;
  applierId: number;
  match: any;
  tabId: number;
  searchChanged$: Subject<string> = new Subject<string>();
  searchSubcription: Subscription;
  searchText: string;
  lat: any = '';
  lng: any = '';
  status: SelectList[] = [];
  ShowFilter: boolean = false;
  dropdownSettings: IDropdownSettings;
  selectedStatus = [];
  selectedCareTypes = [];
  selectedEducations = [];
  selectedReferenceBy = [];
  selectedDateRange: { startDate: Moment; endDate: Moment };
  minDate: Moment;
  maxDate: Moment;
  locale: LocaleConfig = {
    applyLabel: 'OK', // detault is 'Apply'
    clearLabel: 'Clear',
    customRangeLabel: 'Zeitspanne',
    format: 'DD.MM.YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
  };
  ranges: any = {
    Heute: [moment(), moment()],
    Gestern: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  options = {
    types: ['geocode'],
    componentRestrictions: {
      country: ['de'],
    },
  };

  constructor(
    private translate: TranslateService,
    private applicantService: ApplicantService,
    private HistoryService: HistoryService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    public constants: Constants,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.searchSubcription = this.searchChanged$
      .pipe(debounceTime(500))
      .subscribe(input => this.dataSource.queryBy({ search: input }));

    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.applierId = +params['applierId'];
        this.tabId = +params['tabId'];
      })
    );

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.getStatus();
  }

  ngOnDestroy() {
    this.searchSubcription.unsubscribe();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    if (this.applierId && this.tabId !== null) {
      this.toggle(null, this.applierId, this.tabId);
    }
    this.cdr.detectChanges();
  }

  setApplierStatus($event) {
    this.applierData = $event;
  }

  startClose() {
    this.location.go('/applicants');
  }

  openMatchDrawer(match) {
    this.match = match;

    this.matchDrawer.position = 'end';
    this.drawer.position = 'start';

    this.matchDrawer.open();
  }

  closeMatchDrawer(isOpened: boolean) {
    if (isOpened) return;
    this.matchDrawer.position = 'start';
    this.drawer.position = 'end';
  }

  onSelect(dates: { startDate: Moment; endDate: Moment }) {
    this.dataSource.queryBy({
      start_date: dates?.startDate,
      end_date: dates?.endDate,
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  search() {
    this.searchChanged$.next(this.searchText);
  }

  // open the navebar and load applier
  toggle(event: Event, applierId: number, tab: number) {
    if (event) event.stopPropagation();

    this.selectedTab.select(tab);
    this.selectedApplierId = applierId;
    this.location.go('/applicants/' + applierId + '/' + tab);
    this.openDrawer();
  }

  // download applicant pdf profile
  download(row) {
    const applyerCityName =
      `${row.user.first_name} ${row.user.last_name}` +
      '_' +
      row.city.replace('_', ' ');
    this.applicantService.downloadPdf(row.id).subscribe((response: any) => {
      let downloadURL = window.URL.createObjectURL(response);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Kurzprofil_${applyerCityName.split(' ').join('_')}.pdf`;
      link.click();
    });
  }

  downloadFile(row, file) {
    const userName = `${row.user.first_name} ${row.user.last_name}`;
    const link = document.createElement('a');
    link.href = file.file_url;
    link.download = `${file.type}${userName}`;
    link.click();
  }

  getActionNeeded() {
    if (!this.needActionChecked)
      return this.dataSource.queryBy({ need_action: '' });

    this.dataSource.queryBy({ need_action: 'true' });
  }

  getActiveProximity() {
    if (!this.isActiveproximity) {
      this.dataSource.queryBy({ radius_search: '' });
      this.dataSource.queryBy({ latitude: '', longitude: '' });
      this.isAddressActive = false;
    } else {
      this.dataSource.queryBy({ radius_search: 'true' });
      this.isAddressActive = true;
    }
  }

  AddressChange(address: any) {
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    if (!this.isAddressActive)
      return this.dataSource.queryBy({ latitude: '', longitude: '' });

    this.dataSource.queryBy({ latitude: this.lat, longitude: this.lng });
  }

  updateAndClose($event) {
    this._snackBar.open(
      this.translate.instant('message.aplication_update'),
      '',
      {
        duration: 5000,
      }
    );
    this.update($event);
    this.closeDrawer();
  }

  update($event) {
    this._snackBar.open(
      this.translate.instant('message.aplication_update'),
      '',
      {
        duration: 5000,
      }
    );
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  handleError() {
    this._snackBar.open(this.translate.instant('message.something_wrong'), '', {
      duration: 5000,
    });
  }

  closeDrawer() {
    this.drawer.close();
  }

  openDrawer() {
    this.drawer.position = 'end';
    this.drawer.open();
  }

  refreshTable() {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  viewApplicant(row) {
    window.open(
      environment.laenkApps.applicant.url + '/' + row.extID,
      '_blank',
      'noopener noreferrer'
    );
  }

  getStatus() {
    if (this.status?.length) return;
    this.HistoryService.getHistoryStatus().subscribe(
      (response: any) => {
        const statusArray = response?.results.sort((a, b) => a.id - b.id);
        this.status = statusArray.map(status_ => {
          return {
            id: status_.id,
            label: status_.status_name,
          };
        });
      },
      errorRes => {}
    );
  }

  filterApplicants() {
    const status = this.selectedStatus.map(i => i.id).toString();
    const careTypeIds = this.selectedCareTypes.map(i => i.id).toString();
    const educationType = this.selectedEducations.map(i => i.id).toString();
    const referenceBy = this.selectedReferenceBy.map(i => i.id).toString();

    this.dataSource.queryBy({
      status,
      care_type: careTypeIds,
      education_type: educationType,
      reference_by: referenceBy,
    });
  }

  toggles(event, row) {
    const mailToggle = { mail_toggle: event.checked ? 2 : 1 };
    this.applicantService
      .patchApplier(row.id, mailToggle)
      .pipe(take(1))
      .subscribe(
        (response: any) => {},
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
  }
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}
