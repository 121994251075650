import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JobService } from 'src/app/services/job.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  showSpinner = false;
  isRegister = true;
  errorMessage: string;
  successMessage: string;
  profileForm: UntypedFormGroup;
  loginResult: any;
  constructor(
    private _snackBar: MatSnackBar,private jobService: JobService,private translate: TranslateService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.profileForm = new UntypedFormGroup({
      first_name: new UntypedFormControl('', Validators.required),
      last_name: new UntypedFormControl('', Validators.required),
      phone_number: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(17), Validators.pattern("^[0-9]*$")]),
      gender: new UntypedFormControl(''),
      // kolkov: new FormControl(''),
    });
    this.getDetails();
  }

  phoneNumber(event) {
    this.profileForm.patchValue({
      phone_number: event.target.value.replace(/^0+/, '')
    });

  }
 numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  async getDetails() {
    let userData = (JSON.parse(localStorage.getItem('agentData'))) ? JSON.parse(localStorage.getItem('agentData')) : JSON.parse(localStorage.getItem('companyData'));
    await  this.jobService.getLoginUserDetails(userData.id).subscribe((response: any) => {
        this.loginResult = response;
        this.profileForm.patchValue({
          first_name: this.loginResult.first_name,
          last_name: this.loginResult.last_name,
          phone_number: this.loginResult.phone_number.replace('+49', ''),
          gender: this.loginResult.gender,
        });
        this.showSpinner = false;
      }, (errorRes) => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, '', { duration: 5000 });
      });
  }

  async update() {
    let userData = (JSON.parse(localStorage.getItem('agentData'))) ? JSON.parse(localStorage.getItem('agentData')) : JSON.parse(localStorage.getItem('companyData'));
    this.errorMessage = this.successMessage = '';
    if (this.profileForm.valid) {
      this.profileForm.value.email = this.loginResult.email
      this.profileForm.value.role = this.loginResult.role
      this.profileForm.value.phone_number = '+49'+ this.profileForm.value.phone_number.replace('+49', '');
      const postData = this.profileForm.value;      
      await this.jobService.updateUser(userData.id,postData).subscribe((response: any) => {
        this.loginResult = response;
        this.showSpinner = false;
        this.isRegister = false;
        this._snackBar.open(this.translate.instant('profile.profile_update'), '', { duration: 5000 });
      }, (errorRes) => {
        this.showSpinner = false;
        this.isRegister = true;
        this._snackBar.open(errorRes, '', { duration: 5000 });
      });
    }
  }
}

