<div *ngIf="applierData">
  <h1 class="drawer-title">
    {{ applierData?.user?.first_name }} {{ applierData?.user?.last_name }}
  </h1>
  <form [formGroup]="applicantForm">
    <table>
      <tr>
        <td translate>
          home.col_first_name
          <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="first_name" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['first_name'].value }}
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_last_name <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="last_name" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['last_name'].value }}
        </td>
      </tr>

      <tr>
        <td class="label-td" translate>job_details.position</td>
        <td class="edit-td" *ngIf="editMode">
          <ng-multiselect-dropdown
            formControlName="position"
            placeholder="{{ 'job_details.not_selected' | translate }}"
            [settings]="multiSelectWithSearch"
            [data]="constants.getList('position') | async"
            [(ngModel)]="selectedPosition"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let position of selectedPosition">{{
            position?.label
          }}</tag>
        </td>
      </tr>

      <tr *ngIf="applicantForm.controls['position_other'].value">
        <td translate>Sonstige Position</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="position_other" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          <tag> {{ applicantForm.controls['position_other'].value }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_education</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="multiSelectWithSearch"
            [data]="constants.getList('education_type') | async"
            [(ngModel)]="selectedEducationItems"
            [ngModelOptions]="{ standalone: true }"
            formControlName="education"
            (onSelect)="checkEducationList()"
            (onDeSelect)="checkEducationList()"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedEducationItems">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_continuing_education</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            formControlName="continuing_education"
            class="multiselect-dropdown"
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="multiSelectWithSearch"
            [data]="constants.getList('continuing_education_type') | async"
            [(ngModel)]="selectedContinuingEducation"
            [ngModelOptions]="{ standalone: true }"
            (onSelect)="checkContiEducationList()"
            (onDeSelect)="checkContiEducationList()"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedContinuingEducation">{{
            i?.label
          }}</tag>
        </td>
      </tr>

      <tr *ngIf="isContiEduShow">
        <td translate>home.further_training</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              type="text"
              formControlName="continous_education_other"
            />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['education_other'].value }}
        </td>
      </tr>

      <tr *ngIf="isEduOtherShow || !selectedEducationItems.length">
        <td translate>home.education_other</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="education_other" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['education_other'].value }}
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_care_type
          <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="multiSelectWithSearch"
            [data]="constants.getList('care_type') | async"
            [(ngModel)]="selectedCareTypeItems"
            [ngModelOptions]="{ standalone: true }"
            formControlName="care_type"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedCareTypeItems">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_shift_hour
          <span
            *ngIf="
              (editMode && isShiftOtherItem) ||
              (editMode &&
                ShiftOthersItems !== '' &&
                selectedShiftHoursItems.length !== 0) ||
              (editMode && ShiftOthersItems == '')
            "
            class="is-validate"
            >*</span
          >
        </td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownSettings"
            [data]="constants.getList('shift_hour_type') | async"
            [(ngModel)]="selectedShiftHoursItems"
            [ngModelOptions]="{ standalone: true }"
            formControlName="shift_hour"
            (onSelect)="checkShiftHour()"
            (onDeSelect)="checkShiftHour()"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedShiftHoursItems">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_shift_other
          <span *ngIf="editMode && isShiftHoursItem" class="is-validate"
            >*</span
          >
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              type="text"
              formControlName="shift_other"
              [(ngModel)]="ShiftOthersItems"
              (ngModelChange)="checkShiftOthers($event)"
              (focus)="checkShiftOthers($event)"
              (change)="checkShiftOthers($event)"
            />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['shift_other'].value }}
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_scope <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownSettings"
            [data]="constants.getList('shift_time_type') | async"
            [(ngModel)]="selectedScopeItems"
            [ngModelOptions]="{ standalone: true }"
            formControlName="scope"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedScopeItems">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_last_2_employers</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <textarea matInput type="text" formControlName="last_2_employers">
            </textarea>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['last_2_employers'].value }}
        </td>
      </tr>

      <tr>
        <td translate>home.col_experience_in</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownSettings"
            [data]="constants.getList('area_of_experience') | async"
            [(ngModel)]="selectedAreaOfExperience"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedAreaOfExperience">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_work_experience</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            formControlName="work_experience"
            class="multiselect-dropdown"
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropDownSingleSelect"
            [data]="constants.getList('work_experience') | async"
            [(ngModel)]="selectedWorkExperience"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedWorkExperience">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_driving_license</td>
        <td *ngIf="editMode">
          <mat-button-toggle-group formControlName="driving_license">
            <mat-button-toggle
              mat-button
              value="{{ license.id }}"
              *ngFor="
                let license of constants.getList('driver_license_type') | async
              "
              >{{ license.label }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedDrivingLicanse">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_vaccination_status<span *ngIf="editMode" class="is-validate"
            >*</span
          >
        </td>
        <td *ngIf="editMode">
          <mat-button-toggle-group
            name="fontStyle"
            aria-label="Font Style"
            formControlName="vaccination_status"
          >
            <mat-button-toggle
              value="{{ item.id }}"
              *ngFor="
                let item of constants.getList('vaccination_status_type') | async
              "
            >
              {{ item.label }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedVaccinationTypeList">{{
            i?.label
          }}</tag>
        </td>
      </tr>

      <tr *ngIf="applicantForm.controls['vaccination_status'].value == 3">
        <td translate>home.col_vaccination_date</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="vaccination_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['vaccination_date'].value }}
        </td>
      </tr>

      <tr>
        <td translate>home.col_language_skills</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            formControlName="language_skills"
            class="multiselect-dropdown"
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="singleSelectWithSearch"
            [data]="constants.getList('language_skill') | async"
            [(ngModel)]="selectedLanguageSkill"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedLanguageSkill">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>Motivation</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="motivation" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['motivation'].value }}
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_email <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="email" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['email'].value }}
        </td>
      </tr>

      <tr class="contact">
        <td>{{ 'home.contact' | translate }}</td>

        <td class="contact-info" formArrayName="contacts">
          <div
            *ngFor="let contact of selectedContact.controls; let index = index"
          >
            <div [formGroupName]="index" class="contact-item">
              <div class="contact-item__select-input">
                <select
                  name="contact types"
                  id="contact_types"
                  formControlName="contact_type"
                  [attr.disabled]="editMode ? null : true"
                >
                  <option value="">-- Type --</option>
                  <option
                    *ngFor="
                      let type of constants.getList('contact_type') | async
                    "
                    [value]="type?.id"
                  >
                    {{ type?.label }}
                  </option>
                </select>
                <input
                  [attr.disabled]="editMode ? null : true"
                  type="text"
                  formControlName="contact"
                />

                <ng-container
                  *ngIf="
                    contact.value['superchat_url'] &&
                      contact.value['contact_type'] == 'WHATSAPP';
                    then link
                  "
                >
                </ng-container>
                <ng-container
                  *ngIf="
                    !contact.value['superchat_url'] &&
                      contact.controls['contact'].touched;
                    then error
                  "
                >
                </ng-container>
                <ng-template #link>
                  <a
                    matSuffix
                    [href]="contact.value['superchat_url']"
                    class="superchat"
                    target="_blank"
                    aria-label="opens superchat on a new tab"
                  >
                    <mat-icon class="link_icon whatsapp-icon"
                      >whatsapp</mat-icon
                    >
                  </a>
                </ng-template>
                <ng-template #error>
                  <mat-icon
                    class="link_icon"
                    matTooltip="Ungültige Telefonnummer"
                  >
                    error
                  </mat-icon>
                </ng-template>
              </div>
              <mat-slide-toggle
                color="primary"
                formControlName="is_verified"
                [disabled]="!editMode"
              ></mat-slide-toggle>
              <button
                *ngIf="editMode"
                (click)="deleteContact(index)"
                mat-icon-button
                color="warn"
                aria-label="Delete Contact"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </td>
        <button *ngIf="editMode" (click)="addContact()">Add</button>
      </tr>
      <tr>
        <td translate>
          home.col_postcode <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              type="text"
              (ngModelChange)="checkPostCode($event)"
              (focus)="checkPostCode($event)"
              (change)="checkPostCode($event)"
              formControlName="postcode"
            />
            <mat-hint
              class="postcode-invalid"
              *ngIf="
                isPostcodeValid &&
                applicantForm.controls['postcode'].value !== ''
              "
            >
              <p translate>message.postcode_invalid</p>
            </mat-hint>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['postcode'].value }}
        </td>
      </tr>

      <tr>
        <td translate>
          home.col_start_date
          <span *ngIf="editMode" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="start_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['start_date'].value }}
        </td>
      </tr>

      <tr>
        <td translate>home.col-job-newsletter-channels</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            #multiSelect
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownSettings"
            [data]="constants.getList('message_channel') | async"
            [(ngModel)]="subscribedJobNewsletterChannels"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of subscribedJobNewsletterChannels">{{
            i?.label
          }}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_birth_date</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="birth_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['birth_date'].value }}
        </td>
      </tr>

      <tr>
        <td translate>Anmerkung</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="note" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['note'].value }}
        </td>
      </tr>

      <tr *ngIf="showOtherRef">
        <td translate>home.col_other</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="others" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{ applicantForm.controls['others'].value }}
        </td>
      </tr>

      <tr *ngIf="files.length">
        <td>{{ 'home.files' | translate }}</td>

        <td class="files">
          <div class="file" *ngFor="let file of files; let index = index">
            <a href="{{ file.file_url }}" target="_blank">
              {{ file.type | constantLabel : 'user_file_type' | async }}:
              {{ file.file_name }}

              <mat-icon> open_in_new </mat-icon>
            </a>

            <!-- <button
              *ngIf="editMode"
              (click)="deleteFile(file)"
              mat-icon-button
              color="warn"
              aria-label="Delete File"
            >
              <mat-icon>delete</mat-icon>
            </button> -->
          </div>
        </td>
      </tr>

      <tr *ngIf="extID">
        <td translate>home.extID_token</td>
        <td style="height: 40px; padding: 10px 0px">
          <div cdkCopyToClipboard="{{ applicantUrl }}" class="copy-paste">
            <a
              href="{{ applicantUrl }}"
              target="_blank"
              rel="noopener,noreferrer"
              ><span>{{ applicantUrl }}</span></a
            >
            <mat-icon matSuffix title="{{ 'home.extID_token' | translate }}">
              content_copy</mat-icon
            >
          </div>
        </td>
      </tr>

      <tr>
        <td translate>home.col_agent_user</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown
            formControlName="agent_user"
            class="multiselect-dropdown"
            placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="singleSelectWithSearch"
            [data]="dropdownAgents"
            [(ngModel)]="selectedAgent"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedAgent">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr *ngIf="companyId && jobId">
        <td translate>Beworbene Versorgung</td>
        <td *ngIf="editMode">
          <a
            mat-flat-button
            class="drawer-btn-save"
            target="_blank"
            style="margin-bottom: 15px; margin-left: -10px"
            routerLink="/jobs/{{ companyId }}/{{ jobId }}/0/0"
            ><span translate>Zur Versorgung</span></a
          >
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedAgent">{{ i?.label }}</tag>
        </td>
      </tr>

      <tr *ngIf="!isEmptyObject(applierData.utm_params)">
        <td>UTM Params</td>

        <div
          class="param--value"
          *ngFor="let param of applierData.utm_params | keyvalue"
        >
          <i>{{ param.key }}</i> : <span>{{ param.value }}</span>
        </div>
      </tr>
    </table>
  </form>

  <!-- <footer> -->
  <div *ngIf="editMode" class="footer" #footer>
    <button
      [disabled]="applicantForm.invalid"
      mat-flat-button
      color="primary"
      style="margin-bottom: 15px"
      (click)="updateData(true)"
      class="drawer-btn-save"
    >
      <span translate>home.btn_save_and_close</span>
    </button>
    <button
      [disabled]="applicantForm.invalid"
      mat-flat-button
      color="primary"
      style="margin-bottom: 15px"
      (click)="updateData()"
      class="drawer-btn-save"
    >
      <span translate>home.btn_save</span>
    </button>

    <button
      mat-flat-button
      class="drawer-btn-save"
      style="margin-bottom: 15px"
      (click)="close()"
    >
      <span translate>home.btn_close</span>
    </button>
  </div>
  <!-- </footer> -->
</div>
<ng-container *ngIf="!applierData">
  <ngx-skeleton-loader
    count="2"
    appearance="line"
    [theme]="{ 'height.px': 100 }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="5"
    appearance="line"
    [theme]="{ 'height.px': 50 }"
  ></ngx-skeleton-loader>
</ng-container>
