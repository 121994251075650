<div class="content" *ngIf="isLogin">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title translate>login.login</mat-card-title>
    </mat-card-header>
    <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
    <p class="error-message" ngif="errorMessage">{{ errorMessage }}</p>
    <p class="success-message" ngif="successMessage">{{ successMessage }}</p>
    <form class="example-form" [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-card-content>
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="{{ 'login.email' | translate }}" formControlName="email" required>
                <mat-error *ngIf="loginForm.invalid && loginForm.get('email').touched">Please enter email</mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </mat-card-content>

      <mat-card-actions>
        <a class="text-center" routerLink="/register">Kein Account? Dann registriere dich!</a>
      </mat-card-actions>

      <mat-card-actions>
        <button mat-raised-button type="submit" [disabled]="loginForm.invalid" color="primary" >{{ 'login.btn_login' | translate }}</button>
      </mat-card-actions>

    </form>
  </mat-card>
</div>
<div class="content">
  <mat-card  *ngIf="linkexpire">
    <div class="company-list">
      <mat-card-header>
        <mat-card-title>Dein magischer Link läuft ab</mat-card-title>
      </mat-card-header>
      <p class="text-center">Ihre Magie läuft nach 5 Minuten ab, also klicken Sie bitte unten, um den Link zur Anmeldeseite zu öffnen.</p>
      <p class="text-center">Geben Sie auf der Anmeldeseite Ihre E-Mail-Adresse ein und senden Sie den Link erneut.</p>
      <p class="text-center">
        <mat-card-actions>
          <button mat-raised-button (click)="gotoLogin()">Klicken Sie hier, um sich erneut anzumelden</button>
        </mat-card-actions>
      </p>
    </div>
  </mat-card>

  <mat-card *ngIf="checkEmailTemplate">
    <div class="company-list">
      <mat-card-header>
        <mat-card-title>E-Mail erfolgreich gesendet.</mat-card-title>
      </mat-card-header>
      <p class="text-center">
        Bitte überprüfen Sie Ihre E-Mail und bestätigen Sie diese. Ihre E-Mail läuft nach 5 Minuten ab.
      </p>
      <p class="text-center">
        <mat-card-actions>
          <button mat-raised-button (click)="gotoLogin()">Klicken Sie hier, um sich erneut anzumelden</button>
        </mat-card-actions>
      </p>
    </div>
  </mat-card>

</div>