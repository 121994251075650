
<div *ngIf="messageType == 'delete-job'">
    <mat-dialog-content>
      <p>Willst du den Job wirklich löschen?<br>
      <b><span style="color: red;">Alle dazugehörigen Matches / Weiterleitungen werden ebenfalls gelöscht!!!</span></b>  </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button  (click)="cancel()" [mat-dialog-close]="true">nein</button>
      <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
    </mat-dialog-actions>
  </div>
  
