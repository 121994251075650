import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';

import moment, { Moment } from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ExcelExportDialogComponent } from '../common/excel-export-dialog/excel-export-dialog.component';
import { JobService } from 'src/app/services/job.service';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { Constants } from 'src/app/models/abbreviation.model';
import { AgentUser } from 'src/app/type';

moment.locale('de');

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MatchComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  companies = [];
  tabId: any;
  isJobUpdated: any;
  isNewCompany = false;
  isApplier = false;
  parentCompanyId: 0;
  dropdownCompanies = [];
  selectedCompanies = [];
  selectedStatus = [];
  isApplierEduAsstShow = false;
  selectedEducationItems = [];
  selectedApplierEducationTypes = [];
  selectedApplierContiEduItems = [];
  selectedContiEduItems = [];
  selectedReferenceBy = [];
  ShowFilter: boolean = false;
  selectedDateRange: any;
  sorting_order: null;
  match: any;
  curentPage: 0;
  companyId: number;
  jobId: number;
  id: number;
  dropdownAgents = [];
  selectedAgent = [];
  selectedAssignedAgent = [];
  needActionChecked = false;
  applierId: number;
  data: any;
  allData: any[];
  searchText: string;
  searchChanged$: Subject<string> = new Subject<string>();
  searchSubcription: Subscription;
  minDate: Moment;
  maxDate: Moment;
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  // sidebar and tab stuff
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columnsAll = [
    { key: 'applier_name', name: 'Name des Antragstellers' },
    { key: 'date', name: 'Erstellt' },
    { key: 'updated_at', name: 'Aktualisiert unter' },
    { key: 'company_name', name: 'Unternehmensname' },
    { key: 'caretype', name: 'Pflegeart' },
    { key: 'location', name: 'Stadt' },
    { key: 'agent_name', name: 'Kundenbetreuer' },
    { key: 'assigned_to', name: 'Verantwortlicher' },
    { key: 'status', name: 'Status' },
    { key: 'created', name: 'Erstellt' },
    { key: 'action', name: 'Actionsarea' },
    { key: 'company_feedback', name: 'Company Feedback' },
  ];
  columnsToDisplay = [
    'applier_name',
    'date',
    'updated_at',
    'company_name',
    'caretype',
    'location',
    'agent_name',
    'assigned_to',
    'status',
    'created',
    'action',
  ];
  // this key is used to store to local storage,
  // if you are updating the columnsToDisplay you should change this as well
  readonly columnKey = 'matchTable_V4';
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.match(request, query),
    { property: 'forwarding_date' as keyof Sortable, order: 'desc' },
    {
      agent_ids: '',
      search: '',
      stage: '',
      need_action: '',
      company_ids: '',
      start_date: '',
      end_date: '',
      education_type: '',
      continuing_education: '',
      applier_education_type: '',
      applier_continuing_education: '',
      reference_by: [],
      assigned_to: '',
      include_hidden: false,
      interview_took_place: '',
      interview_from: '',
      interview_to: '',
      due_date_from: '',
      due_date_to: '',
    },
    2
  );
  locale: LocaleConfig = {
    applyLabel: 'OK', // detault is 'Apply'
    customRangeLabel: 'Zeitspanne',
    format: 'DD.MM.YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
  };
  ranges: any = {
    Heute: [moment(), moment()],
    Gestern: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [];

  constructor(
    private jobService: JobService,
    private historyMatchService: HistoryMatchService,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private location: Location,
    public dialog: MatDialog,
    public constants: Constants
  ) {}

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        if (!Object.keys(params).length) return;

        this.applierId = +params['applierId'];
        this.companyId = +params['companyId'];
        this.jobId = +params['jobId'];
        this.id = +params['id'];
        this.tabId = +params['tabId'];
      })
    );

    this.searchSubcription = this.searchChanged$
      .pipe(debounceTime(500))
      .subscribe(input => this.dataSource.queryBy({ search: input }));

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };

    this.getAgentList();
    this.getCompanyUser();
  }

  ngAfterViewInit() {
    this.getMatch(null, this.tabId, { matchId: this.id });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
    this.searchSubcription.unsubscribe();
  }

  getBySearch() {
    this.searchChanged$.next(this.searchText);
  }

  queryBy(by, queryNgModel) {
    const query = {};
    query[by] = queryNgModel.length ? queryNgModel.map(x1 => x1.id) : '';
    this.dataSource.queryBy(query);
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }

  htmlToPlainText(value): string {
    const element = document.createElement('div');
    element.innerHTML = value;
    return element.textContent || element.innerText || '';
  }

  getAgentList() {
    this.jobService.agents.subscribe((res: AgentUser[]) => {
      this.dropdownAgents = (res || []).map(agent => ({
        id: agent.id,
        label: agent.first_name + ' ' + agent.last_name,
      }));
    });
  }

  onSelect(e) {
    var startdt = new Date(this.selectedDateRange?.startDate?._d);
    var enddt = new Date(this.selectedDateRange?.endDate?._d);

    let start_date =
      this.selectedDateRange?.startDate?._d === undefined
        ? ''
        : startdt.getFullYear() +
          '-' +
          ((startdt || new Date()).getMonth() + 1) +
          '-' +
          startdt.getDate();
    let end_date =
      this.selectedDateRange?.endDate?._d === undefined
        ? ''
        : enddt.getFullYear() +
          '-' +
          ((enddt || new Date()).getMonth() + 1) +
          '-' +
          enddt.getDate();

    this.dataSource.queryBy({ start_date: start_date, end_date: end_date });
  }

  startClose() {
    this.location.replaceState('/forwardings');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getAgentList();
    this.getCompanyUser();
  }

  updateNeedAction(data) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  getMatch(match, tabid, { matchId = null } = {}) {
    if (!match && !matchId) return;
    this.historyMatchService.getMatchById(match?.id ?? matchId).subscribe({
      next: (response: any) => {
        this.match = response;
        this.selectedTab.select(tabid);
        (this.companyId = this.match?.company?.id),
          (this.applierId = this.match?.applier?.id),
          (this.jobId = this.match?.job?.id),
          (this.id = this.match?.id),
          (this.tabId = tabid);
        this.isApplier = false;
        this.isNewCompany = false;

        this.location.go(
          `/forwardings/${this.match?.applier?.id}/${this.match?.company?.id}/${this.match?.job?.id}/${this.match.id}/${tabid}`
        );
        this.drawer.open();
      },
      error: err => console.error(err),
    });
  }

  getCompanyUser() {
    this.jobService.getCompanies({ minimal: true }).subscribe((res: any) => {
      this.dropdownCompanies = res.map(company => ({
        id: company.id,
        label: company.name,
      }));
    });
  }

  checkIsJobUpdated($event) {
    this.getAgentList();
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  getActionNeeded() {
    if (!this.needActionChecked) {
      this.dataSource.queryBy({ need_action: '' });
    } else {
      this.dataSource.queryBy({ need_action: 'true' });
    }
  }

  includeHiddenMatches(event) {
    this.dataSource.queryBy({
      include_hidden: event.checked,
    });
  }

  getInterviewTookPlace(event) {
    this.dataSource.queryBy({
      interview_took_place: (!event.checked)?.toString(),
    });
  }

  getInterviewFrom(event) {
    this.dataSource.queryBy({
      interview_from: event?.value?.toISOString() ?? '',
    });
  }

  getInterviewTo(event) {
    this.dataSource.queryBy({
      interview_to: event?.value?.toISOString() ?? '',
    });
  }

  getDueDateFrom(event) {
    this.dataSource.queryBy({
      due_date_from: event?.value?.format('YYYY-MM-DD') ?? '',
    });
  }

  getDueDateTo(event) {
    this.dataSource.queryBy({
      due_date_to: event?.value?.format('YYYY-MM-DD') ?? '',
    });
  }

  exportDialog() {
    const dialogRef = this.dialog.open(ExcelExportDialogComponent, {
      data: {
        dataSource: {
          ...this.dataSource['query']._value,
          agent_ids: this.dataSource['query']?._value?.agent_ids,
        },
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
