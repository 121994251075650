import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatchHistoryComponent } from '../history.component';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-flag',
  templateUrl: './confirm-flag.component.html',
  styleUrls: ['./confirm-flag.component.scss'],
})
export class ConfirmFlagComponent {
  flag: string;
  matchId: number;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private historyMatchService: HistoryMatchService,
    private dialogRef: MatDialogRef<MatchHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.flag = data.flag;
    this.matchId = data.matchId;
  }

  cancel() {
    this.dialogRef.close({ flagCreated: false, flag: this.flag });
  }

  confirm(send_reminder_to_company) {
    this.historyMatchService
      .createMatchFlags({
        send_reminder_to_company: send_reminder_to_company,
        flag: this.flag,
        match: this.matchId,
      })
      .subscribe(
        response => {
          this._snackBar.open('flag created successfully', 'OK', {
            duration: 3000,
          });
          this.dialogRef.close({ flagCreated: true, flag: this.flag });
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          this.cancel();
        }
      );
  }
}
