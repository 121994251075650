import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

import { AbbreviationModel, Constants } from '../models/abbreviation.model';
import { ConstItem, ConstantKeys } from '../services/constants.service';

@Pipe({
  name: 'constantLabel',
})
export class ConstantLabelPipe implements PipeTransform {
  constructor(private constants: Constants) {}
  transform(
    value: string | number | number[],
    key: ConstantKeys,
    ...args: unknown[]
  ): unknown {
    return this.constants.getList(key).pipe(
      map(constant => {
        if (Array.isArray(value)) {
          if (!value.length) return 'keine Angabe';
          const label = value
            .flatMap(v => constant.find((c: ConstItem) => c?.id == v)?.label)
            .join(', ');
          return label;
        }
        return (
          constant.find((c: ConstItem) => c?.id == value)?.label ??
          'keine Angabe'
        );
      })
    );
  }
}

@Pipe({
  name: 'HistoryMatchChannelAbbreviations',
})
export class HistoryMatchChannelAbbreviationsById implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return new AbbreviationModel().getById(value, 'historyMatch', 'icon');
  }
}
