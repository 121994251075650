import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, switchMap, take } from 'rxjs/operators';
import { Constants } from 'src/app/models/abbreviation.model';
import { actors, getActor } from 'src/app/models/abbreviations/applierStatus';
import { getCommuncationLabel } from 'src/app/models/abbreviations/communicationsTypes';
import { ConstItem } from 'src/app/services/constants.service';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { ConfirmFlagComponent } from './confirm-flag/confirm-flag.component';

@Component({
  selector: 'app-match-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class MatchHistoryComponent implements OnInit {
  @Input() applierId: number;
  @Input() match: any;
  @Input() agents: any[];
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() refreshApplier = new EventEmitter<any>();
  @Output() updateParentNeedAction = new EventEmitter<any>();
  communcationLabel = getCommuncationLabel;
  getActorLabel = getActor;
  icn: string;
  chnlnm: string;
  showSpinner = false;
  historyForm: UntypedFormGroup;
  statis: any;
  now: any = new Date();
  need_action = false;
  protected = false;
  isDueDate = true;
  formSubmitted = false;
  status: any = [];
  labelIds: number[] = [];
  returnValue: string;
  selectedMatchStage: string;
  selectedDate: string = '';
  dropdownSettings: any = {};
  singleSelectDropdownSettings: any = {};
  selectedFlag = [];
  histories: any;
  interviewTookPlace: boolean = false;
  historiesIsEmpty = false;
  baseDropDownSettigs = {
    singleSelection: true,
    idField: 'id',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    searchPlaceholderText: 'Suche',
    itemsShowLimit: 6,
    allowSearchFilter: false,
    enableCheckAll: false,
  };
  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private historyMatchService: HistoryMatchService,
    public dialog: MatDialog,
    public constants: Constants
  ) {}

  /**
   * DEFAULT METHOD FOR ANGULAR AUTOLOADED MATHOD
   */
  ngOnInit(): void {
    this.initForm();
    this.dropdownSettings = {
      ...this.baseDropDownSettigs,
      singleSelection: false,
    };
    this.singleSelectDropdownSettings = {
      ...this.baseDropDownSettigs,
      allowSearchFilter: true,
    };
  }

  /**
   * INITIALIZE FORM WHEN PAGE ARE LOADED
   */
  initForm() {
    this.selectedMatchStage = this.match?.stage;
    this.need_action = this?.match?.need_action;
    this.protected = this?.match?.protected;
    this.setSelectedDate(this.match);
    this.historyForm = this._formBuilder.group(
      {
        match_id: [this.match?.id],
        content: [''],
        status_id: [''],
        due_date: [],
        assigned_to: [],
      },
      {
        validators: [this.atLeastOneContentOrStatusIfDueAndAssigned],
      }
    );
    this.getMatchFlags();
    this.getStatus();
    this.getMatchHistory();
  }

  get getAssignedTo() {
    return this.historyForm.get('assigned_to') as UntypedFormControl;
  }

  get getDueDate() {
    return this.historyForm.get('due_date') as UntypedFormControl;
  }

  atLeastOneContentOrStatusIfDueAndAssigned(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const dueDate = control.get('due_date')?.value;
    const assignedTo = control.get('assigned_to')?.value;
    const content = control.get('content')?.value;
    const statusId = control.get('status_id')?.value;

    if (!dueDate && !assignedTo?.length && !content && !statusId) {
      return { formCannotBeBlank: true };
    }

    if (dueDate && assignedTo?.length && !content && !statusId) {
      return { contentOrStatusRequired: true };
    }

    return null;
  }

  onDueDateChange() {
    if (this.getDueDate.value) {
      this.getAssignedTo.setValidators([Validators.required]);
    } else {
      this.getAssignedTo.clearValidators();
    }
    this.getAssignedTo.updateValueAndValidity();
  }

  onAgentChange() {
    if (this.getAssignedTo.value?.length) {
      this.getDueDate.setValidators([Validators.required]);
    } else if (this.getDueDate.value) {
      this.onDueDateChange();
    } else {
      this.getDueDate.clearValidators();
    }
    this.getDueDate.updateValueAndValidity();
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  setDifference(setA, setB) {
    const _difference = new Set(setA);
    for (const elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  setSelectedDate(match) {
    if (!['ACCEPTED', 'INTERVIEW', 'SHADOWING'].includes(match.stage)) return;

    if (match.company_feedback)
      switch (match.stage) {
        case 'ACCEPTED':
          this.selectedDate = match.company_feedback.start_date ?? '';
          break;
        case 'INTERVIEW':
          this.selectedDate = match.company_feedback.interview_date ?? '';
          this.interviewTookPlace =
            match.company_feedback.interview_took_place ?? false;
          break;
        case 'SHADOWING':
          this.selectedDate = match.company_feedback.shadowing_date ?? '';
          break;
      }
  }

  getFieldLabel(changes: {
    field: string;
    old: any[] | number;
    new: any[] | number;
  }) {
    const mappings = {
      salary: 'Gehalt',
      start_date: 'Einstiegsdatum',
      interview_date: 'Interviewdatum',
      shadowing_date: 'Hospitationsdatum',
      termination_date: 'Kündigungsdatum',
      termination_reason: 'Kündigungsgrund',
      terminated_by: 'Gekündigt von',
      rejection_reason: 'Ablehnungsgrund',
      rejected_by: 'Abgelehnt von',
      company_note: 'Company note',
    };

    return mappings[changes.field] ?? null;
  }

  companyFeedbackCount(feedback: Record<string, any> = {}): number {
    return Object.values(feedback).filter(Boolean).length;
  }

  matchFlagLabels(flags: string[]): string {
    return (flags || [])
      .flatMap(val => {
        return this.constants.getMatchFlag(val)?.label ?? '';
      })
      .join(' , ');
  }

  updateNeedAction(need_action: boolean) {
    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, {
        need_action,
      })
      .subscribe({
        next: resp => {
          this.updateParentNeedAction.emit({
            match_id: this.match?.id,
            need_action,
          });
          this._snackBar.open('Need Action Updated', 'OK', { duration: 5000 });
        },
        error: e => {
          this._snackBar.open('Something Went wrong', 'OK', { duration: 5000 });
        },
      });
  }

  toggleProtectedOnMatch(isProtected: boolean) {
    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, {
        protected: isProtected,
      })
      .subscribe({
        next: () => {
          const message = isProtected
            ? 'Match has been protected'
            : 'Match is no longer protected';
          this._snackBar.open(message, 'OK', { duration: 5000 });
        },
        error: e => {
          this._snackBar.open('Something Went wrong', 'OK', { duration: 5000 });
        },
      });
  }

  getStatusText(status): string {
    return this.constants.getMatchStage(status)?.label ?? '';
  }

  flagLabel(log: any) {
    const newFlags = log?.details?.new_flags ?? [];
    const oldFlags = log?.details?.old_flags ?? [];

    if (
      newFlags.length == oldFlags.length &&
      log.actor === actors.SYSTEM.id &&
      log.type === 'FLAG'
    ) {
      const flags = this.matchFlagLabels([
        ...this.setDifference(newFlags, oldFlags),
      ] as string[]);
      return `Das System hat den Flag zu "${flags}" geändert`;
    }

    if (newFlags.length > oldFlags.length) {
      const flags = this.matchFlagLabels([
        ...this.setDifference(newFlags, oldFlags),
      ] as string[]);

      return ` Das  Flag "${flags}" wurde gesetzt `;
    } else {
      const flags = this.matchFlagLabels([
        ...this.setDifference(oldFlags, newFlags),
      ] as string[]);

      return `Das Flag "${flags}" wurde entfernt`;
    }
  }

  statusChangeLabel(log) {
    if (!log?.old_status?.length && log?.status)
      return 'Ein neues Match wurde gefunden';
    if (log.actor === actors.SYSTEM.id)
      return `Das System hat den Status zu "${this.getStatusText(
        log?.status
      )}" geändert`;

    return `${log?.created_by?.first_name} ${
      log?.created_by?.last_name || log?.user?.last_name
    }  (${
      getActor(log?.actor)?.label
    })  hat den Status zu "${this.getStatusText(
      log?.new_status || log?.status
    )}" geändert`;
  }

  /**
   *
   * @param days ADD DAYS IN TODAY DATE AND SET INTO NEW DUE_DATE
   */
  updateDate(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    this.historyForm.controls['due_date'].clearValidators();
    if (days) {
      this.historyForm.patchValue({
        due_date: date,
      });
      this.isDueDate = true;
      this.historyForm.controls['due_date'].setValidators([
        Validators.required,
      ]);
    } else {
      this.historyForm.patchValue({
        due_date: null,
      });
      this.isDueDate = false;
      this.historyForm.controls['due_date'].clearValidators();
    }
  }

  getMatchFlags() {
    this.constants
      .getList('match_flag')
      .pipe(
        switchMap(matchFlags => {
          return this.historyMatchService.getMatchFlags(this.match?.id).pipe(
            map((presectedFlags: Array<any>) => {
              this.selectedFlag = presectedFlags.map(({ flag }) => {
                return matchFlags.find((i: ConstItem) => i.id == flag);
              });
            })
          );
        }),
        take(1)
      )
      .subscribe({
        error: errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        },
      });
  }

  getMatchHistory() {
    this.historyMatchService.getMatchHistoryHistory(this.match?.id).subscribe(
      (data: Array<any>) => {
        const histories = (data ?? []).reduce((accumulator, currentValue) => {
          const key = new Date(currentValue?.applier?.created_at)
            .toISOString()
            .split('T')[0];
          if (!accumulator[key]) accumulator[key] = [];
          accumulator[key].push(currentValue);
          return accumulator;
        }, {});

        this.histories = histories;
        this.historiesIsEmpty = !Object.keys(histories).length;
        this.showSpinner = false;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  onFlagSelect($event, openDialog = true) {
    if ($event?.id === 'UNRESPONSIVE_COMPANY' && openDialog) {
      const dialogRef = this.dialog.open(ConfirmFlagComponent, {
        data: {
          matchId: this.match?.id,
          flag: $event.id,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result?.flagCreated) {
          this.getMatchHistory();
        } else {
          const index = this.selectedFlag.findIndex(f => f.id == result?.flag);
          if (index == -1) return;
          this.selectedFlag.splice(index, 1);
          this.selectedFlag = [...this.selectedFlag];
        }
      });
      return;
    }

    this.historyMatchService
      .createMatchFlags({
        flag: $event.id,
        match: this.match?.id,
      })
      .subscribe(response => {
        this.getMatchHistory();
        this._snackBar.open('flag created successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  onFlagDeSelect($event) {
    this.historyMatchService
      .deletedMatchFlags(this.match?.id, { flag: $event.id })
      .subscribe(response => {
        this.getMatchHistory();
        this._snackBar.open('flag removed successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  /**
   * ADD MATCH HISTORY
   */
  submitHistory() {
    this.formSubmitted = true;
    let due_date;
    if (this.historyForm.value.due_date) {
      const newdate = new Date(this.historyForm.value.due_date);
      due_date = `${newdate.getFullYear()}-${
        newdate.getMonth() + 1
      }-${newdate.getDate()}`;
    }

    if (this.historyForm.invalid) return;

    const data = {
      content: this.historyForm.value.content,
      match_id: Number(this.match?.id),
      status_id: this.historyForm.value.status_id,
      due_date: due_date ?? null,
      assigned_to:
        (this.historyForm?.value?.assigned_to ?? []).map(
          agent => agent?.id
        )?.[0] ?? null,
    };

    this.showSpinner = true;
    this.historyMatchService.addHistoryMatchData(data).subscribe(
      (response: any) => {
        this.getMatchHistory();
        this.refreshApplier.emit();
        this.historyForm.reset();
        this.update.emit(response);
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      },
      () => {
        this.formSubmitted = false;
        this.showSpinner = false;
      }
    );
  }

  resetSelectedDate() {
    this.selectedDate = '';
  }

  updateMatchStatus() {
    const selectedDate = this.selectedDate ? new Date(this.selectedDate) : '';

    const event = {};
    switch (this.selectedMatchStage) {
      case 'ACCEPTED':
        event['start_date'] = selectedDate;
        break;
      case 'INTERVIEW':
        event['interview_date'] = selectedDate;
        event['interview_took_place'] = this.interviewTookPlace;
        break;
      case 'SHADOWING':
        event['shadowing_date'] = selectedDate;
        break;
    }
    const data = {
      stage: this.selectedMatchStage,
      company_feedback: {
        salary: '',
        start_date: '',
        interview_date: '',
        interview_took_place: false,
        shadowing_date: '',
        termination_date: '',
        termination_reason: '',
        terminated_by: '',
        rejection_reason: '',
        rejected_by: '',
        ...this.match.company_feedback,
        ...event,
      },
    };

    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, data)
      .subscribe(res => {
        this.match = res;
        this.getMatchHistory();
      });
  }

  getStatus() {
    this.showSpinner = true;
    this.historyMatchService.getHistoryMatchStatus().subscribe(
      (response: any) => {
        this.status = response?.results;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      },
      () => {
        this.showSpinner = false;
      }
    );
  }

  completeTask(key, historyId, marked_completed) {
    this.historyMatchService
      .patchMatchHistoryHistory(historyId, {
        marked_completed: marked_completed,
      })
      .subscribe(response => {
        let index = (this.histories?.[key] ?? []).findIndex(
          history => history.id == historyId
        );
        if (index == -1) return;
        this.histories[key][index].completed_at = marked_completed;
      });
  }
}
