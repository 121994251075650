<div class="profile">
  <mat-card class="profile-card">
    <mat-card-header>
      <mat-card-title translate>profile.title</mat-card-title>
    </mat-card-header>
    <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
    <p class="error-message" ngif="errorMessage">{{ errorMessage }}</p>
    <p class="success-message" ngif="successMessage">{{ successMessage }}</p>
    <form class="example-form" [formGroup]="profileForm" (ngSubmit)="update()">
      <mat-card-content>
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-radio-group
                aria-labelledby="gender"
                formControlName="gender"
                class="gender-radio"
              >
                <div><mat-label>Anrede</mat-label></div>
                <mat-radio-button
                  [checked]="[loginResult?.gender == 1 ? true : false]"
                  value="1"
                >
                  {{ 'profile.male' | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [checked]="[loginResult?.gender == 2 ? true : false]"
                  value="2"
                  style="margin-left: 10px"
                  translate
                >
                  {{ 'profile.female' | translate }}</mat-radio-button
                >
              </mat-radio-group>
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  placeholder="{{ 'profile.first_name' | translate }}"
                  formControlName="first_name"
                  required
                />
                <mat-error
                  *ngIf="
                    profileForm.invalid &&
                    (profileForm.get('first_name').invalid ||
                      profileForm.get('first_name').dirty ||
                      profileForm.get('first_name').touched)
                  "
                >
                  Please enter first name</mat-error
                >
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  placeholder="{{ 'profile.last_name' | translate }}"
                  formControlName="last_name"
                  required
                />
                <mat-error
                  *ngIf="
                    profileForm.invalid &&
                    (profileForm.get('last_name').invalid ||
                      profileForm.get('last_name').dirty ||
                      profileForm.get('last_name').touched)
                  "
                >
                  Please enter last name</mat-error
                >
              </mat-form-field>
              <mat-form-field class="example-full-width phone_number">
                <span matPrefix>+49&nbsp;</span>
                <input
                  (keyup)="phoneNumber($event)"
                  (keypress)="numberOnly($event)"
                  matInput
                  placeholder="{{ 'profile.phone_number' | translate }}"
                  formControlName="phone_number"
                  required
                />
                <mat-error
                  *ngIf="
                    profileForm.invalid &&
                    (profileForm.get('phone_number').invalid ||
                      profileForm.get('phone_number').dirty ||
                      profileForm.get('phone_number').touched)
                  "
                >
                  Please enter phone number</mat-error
                >
              </mat-form-field>
            </td>
          </tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="submit" color="primary">
          {{ 'profile.btn_update' | translate }}
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
